import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import {
  faArrowRight,
  faArrowLeft,
  faLightbulbOn,
  faCircleXmark,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Switch, Tab } from "@headlessui/react";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { IGoalSteps } from "../interface/generic";

export interface IProps {
  title?: string;
  description?: string;
  goalDetails?: any;
  convoDate?: string;
  isManager?: boolean;
  convStatus?: any;
}

type LocationProps = {
  state: {
    data: any;
    status: any;
    id: any;
  };
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CheckInGoal = (props: IProps) => {
  const [conversation, setConversation] = useState("prepare");
  const [completeGoal, setCompleteGoal] = useState(false);
  const [currentStep, setCurrentStep] = useState<IGoalSteps>();
  const [goalSteps, setGoalSteps] = useState([]);

  const location = useLocation() as unknown as LocationProps;
  const dateFromConv = location.state?.data
  const convStatus = location.state?.status
  const agreementIdProps = location.state?.id

  const [checkInDetails, setCheckInDetails] = useState({
    goal_id: props.convStatus === 1 ? props.goalDetails.goal_id : null,
    date: props.convoDate,
    agreement_id: props.convStatus === 1 ? props.goalDetails.agreement_id : null,
    complete_goal: props.convStatus === 1 ? props.goalDetails.complete_goal : null,
    improved_or_developed_notes: props.convStatus === 1 ? props.goalDetails.measuring_success : null,
    steps: props.goalDetails === 1 ? props.goalDetails.steps : null,
    previousQuestion: {},
  });

  const time = props.goalDetails.completion_date;
  const startTime = props.goalDetails.created_at;
  const startTimeDate = new Date(startTime).toDateString();

  var start = new Date(startTimeDate),
    end = new Date(time),
    today = new Date();

  var startDate = start.getTime();
  var endDate = end.getTime();
  var todayDate = today.getTime();

  // Get the total possible timestamp value
  var total = endDate - startDate;

  // Get the current value
  var current = todayDate - startDate;

  // Get the percentage
  var p = Math.round((current / total) * 100) + "%";

  const progressWidth = {
    width: p,
  };

  const processStep = (type) => {
    if (type === "step")
      if (currentStep) setGoalSteps((goalSteps) => [...goalSteps, currentStep]);
  };

  const removeStep = (step) => {
    setGoalSteps(goalSteps.filter((item) => item.title !== step.title));
  };

  const updateAction = (name, index) => (event) => {
    let newArr = goalSteps.map((item, i) => {
      if (index === i && name === "description") {
        return { ...item, [name]: event.target.value };
      }
      if (index === i && name === "status") {
        return { ...item, [name]: event.target.checked };
      } else {
        return item;
      }
    });
    setGoalSteps(newArr);
  };

  const submitCheckIn = () => {
    const axios = require("axios");
    const token = localStorage.getItem("token");
    axios({
      method: "post",
      url: `https://api.qonvr.co/api/conversation/goal-check-in`,
      crossDomain: true,
      data: checkInDetails,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response;
        //console.log(data);
        setConversation("complete")
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  useEffect(() => {
    if(goalSteps.length === 0) {
        setGoalSteps((goalSteps) => [
        ...goalSteps,
        ...props.goalDetails.steps,
      ]);
    }
  }, []);

  useEffect(() => {
    setCheckInDetails({ ...checkInDetails, steps: goalSteps });
  }, [goalSteps]);

  useEffect(() => {

  }, [checkInDetails]);

  useEffect(() => {
    setCheckInDetails({ ...checkInDetails, complete_goal: completeGoal });
  }, [completeGoal]);

  return (
    <>
      {conversation === "prepare" && (
   
          <div className="grid grid-cols-12 gap-10">
            <div className="col-span-12 p-4">
              <h2 className="font-medium mb-2 text-2xl">
                {props.goalDetails.name}
              </h2>
              <p className="mb-8 text-gray-500">
                {props.goalDetails.description}
              </p>
              <div className=" shadow bg-white pb-6 pt-4 px-6 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <p className="font-medium">Your goal progress {p}</p>
                  <Switch.Group
                    as="div"
                    className="flex items-center hover:cursor-pointer"
                  >
                    <Switch
                      disabled={props.isManager || props.convStatus === "1"}
                      checked={completeGoal}
                      onChange={setCompleteGoal}
                      className={classNames(
                        completeGoal ? "bg-blue-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 disabled:bg-gray-100 disabled:shadow-none"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          completeGoal ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3">
                      <span className="text-sm font-medium text-gray-900">
                        Complete goal
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                  
                </div>
                <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                  <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={progressWidth}
                  >
                    {p}
                  </div>
                </div>
                {completeGoal && progressWidth.width < '100%' &&
                  <Alert
                  severity="info"
                  className="mt-3"
                  icon={<FontAwesomeIcon icon={faInfoCircle as IconProp} />}
                  >
                    Heads up, your goal is not nearing your expected completion date yet.
                  </Alert>}
              </div>
              <div className="mt-8 mb-8">
                <p className="text-xl  font-medium text-gray-600 mb-4">
                  What action/s have you completed?
                </p>
                <ul className=" shadow bg-white py-3 px-6 rounded-lg mb-8">
                  {goalSteps.map((step, i) => (
                    <li key={i} className="relative bg-white py-3 text-sm ">
                      <div className="relative flex items-start mb-3">
                        <div className="flex items-center h-5">
                          <input
                            disabled={props.isManager || props.convStatus === "1"}
                            name={i.toString()}
                            id={i.toString()}
                            type="checkbox"
                            checked={step.status === "No" ? false : step.status}
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded disabled:bg-gray-100 disabled:shadow-none"
                            onChange={updateAction("status", i)}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor={i.toString()} className="text-gray-700 hover:cursor-pointer">
                            {step.title}
                          </label>
                        </div>
                      </div>
                      <textarea
                        disabled={props.isManager || props.convStatus === "1"}
                        id={"about"}
                        name="about"
                        rows={3}
                        className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-3 disabled:bg-gray-100 disabled:shadow-none"
                        placeholder="Notes"
                        onChange={updateAction("description", i)}
                        value={step.description}
                      />
                    </li>
                  ))}
                </ul>
                <Alert
                  severity="warning"
                  icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                >
                  {props.isManager ? "Ask questions about the process; Ask 'how' questions, rather than 'why'" : "If you are not able to complete the action, you can do it by the next check-in" }
                </Alert>
              </div>

              <div className="flex justify-end mt-8">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  onClick={(e) => {
                    setConversation("discuss");
                    setCheckInDetails({
                      ...checkInDetails,
                      goal_id: props.goalDetails.id,
                      agreement_id: props.goalDetails.agreement_id,
                    });
                  }}
                >
                  Next
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowRight as IconProp}
                  />
                </button>
              </div>
            </div>
          </div>
   
      )}
      {conversation === "discuss" && (
   
          <div className="grid grid-cols-12 gap-10">
            <div className="col-span-12 p-4">
              <h2 className="font-medium mb-2 text-2xl">
                What have you learnt, improved or developed?
              </h2>
              <p className="mb-8 text-gray-500">
                Discuss and write down the things you have learnt in your
                thinking or performance
              </p>
              <textarea
                disabled={props.isManager || props.convStatus === "1"}
                id="about"
                name="about"
                rows={8}
                className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8 disabled:bg-gray-100 disabled:shadow-none"
                placeholder=""
                value={checkInDetails.improved_or_developed_notes}
                onChange={(e) => {
                  setCheckInDetails({
                    ...checkInDetails,
                    improved_or_developed_notes: e.target.value,
                  });
                }}
              />

              <Alert
                severity="warning"
                icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
              >
                {props.isManager ? "When clarifying learning : Tell team members when they're doing something smart, not just being smart." : "If you find you haven't learnt, improved or developed, have a discussion about why it hasn't worked and consider doing things differently."}
                
              </Alert>

              <div className="flex justify-between mt-8">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={(e) => setConversation("prepare")}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faArrowLeft as IconProp}
                  />
                  Back
                </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={(e) => setConversation("action")}
                >
                  Next
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowRight as IconProp}
                  />
                </button>
              </div>
            </div>
          </div>
     
      )}
      {conversation === "action" && (

          <div className="grid grid-cols-12 gap-10">
            <div className="col-span-12 p-4">
              <h2 className="font-medium mb-4 text-2xl">
                What is the next step or action you have to take?
              </h2>
              <div className="bg-blue-100 rounded-md p-4 mt-5">
                <div className="sm:flex sm:items-center">
                  <div className="w-9/12">
                    <input
                      disabled={props.isManager || props.convStatus === "1"}
                      type="text"
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100 disabled:shadow-none"
                      placeholder="Your steps / action towards your goal"
                      value={currentStep?.title || ""}
                      onChange={(e) =>
                        setCurrentStep({
                          ...currentStep,
                          title: e.currentTarget.value,
                          description: e.currentTarget.value,
                          status: false,
                        })
                      }
                    />
                  </div>

                  <button
                    disabled={props.isManager || props.convStatus === "1"}
                    type="button"
                    onClick={(e) => {
                      processStep("step");
                      setCurrentStep({});
                    }}
                    className="mt-3 w-3/12 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:text-sm disabled:bg-gray-400 disabled:shadow-none"
                  >
                    Add step / action
                  </button>
                </div>
              </div>
              {checkInDetails?.steps?.length >= 1 && (
                <div className="bg-white border-2 border-gray-200 rounded-md p-4 mt-3 mb-8">
                  <ul className="divide-y divide-gray-300">
                    {checkInDetails?.steps?.filter(item => item.status === false).map((step, i) =>
                    (
                      <li
                        key={i}
                        className="relative py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 text-sm "
                      >
                        <span className="flex items-center justify-between">
                          <p>{step.title}</p>
                          {!props.isManager || props.goalDetails.status !== 1 &&
                          <button
                            onClick={() => {
                              removeStep(step);
                            }}
                          >
                            <FontAwesomeIcon
                              className="text-base text-red-500"
                              icon={faCircleXmark as IconProp}
                            />
                          </button>
}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Alert
                severity="warning"
                icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
              > {props.isManager ? "Cultivate grit - Team members with that extra bit of determination will be more likely to seek approval from themselves rather than others." : "Make sure your step is clearly defined and small to ensure progress."}
              </Alert>
              <div className="flex justify-between mt-8">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={(e) => setConversation("discuss")}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faArrowLeft as IconProp}
                  />
                  Back
                </button>
                <button
                  type="button"
                  disabled={props.convStatus === 1}
                  className="disabled:bg-gray-300 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={(e) => {
                    props.isManager ? setConversation("complete") : submitCheckIn();
                  }}
                >
                  Complete
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowRight as IconProp}
                  />
                </button>
              </div>
            </div>
          </div>
  
      )}
      {conversation === "complete" && (
   
          <div className="text-center">
            <img src="/set-goal-conv.png" alt="" className="w-64 m-auto" />
            <h1 className="text-3xl mb-3 mt-8">Great!</h1>
            <p className="text-gray-500 mb-8">
              You have completed your check in for <strong>{props.goalDetails.name}</strong>
            </p>
     
          </div>
   
      )}
    </>
  );
};

export default CheckInGoal;
