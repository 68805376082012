import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faCalendar,
  faCalendarCheck,
  faPenToSquare,
} from "@fortawesome/pro-light-svg-icons";
import { faLacrosseStick } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon, MailIcon } from "@heroicons/react/solid";
import XIcon from "@heroicons/react/solid/XIcon";
import { Alert } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import AddGoal from "../components/add-goal";
import Loading from "../components/loading";

import { GOALS_STATUS } from "../control/general";
import { IGoal, IUserProfile } from "../interface/generic";
export interface IProps {
  title?: string;
}
const axios = require("axios");
const GoalsPage = (props: IProps) => {
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [goalDetails, setGoalDetails] = useState([]);
  const [userType, setUserType] = useState();
  const [agreementId, setAgreementId] = useState();
  const [goalAgreements, setGoalAgreements] = useState([]);
  const [goalUsers, setGoalUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [userProfile, setUserProfile] = useState<IUserProfile>()
  const [goalPopupDetails, setGoalPopupDetails] = useState({
    name: null,
    description: null,
    completion_date: null,
  });
  const [currentSelectedGoalId, setCurrentSelectedGoalId] = useState(0)

  const [addGoalOpen, setAddGoalOpen] = useState(false);

  const getUserProfile = () => {
    setIsLoading(true)
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        if(data.agreements.length > 0) {
          getGoals(data.agreements[0].id);
          setAgreementId(data.agreements[0].id);
          setGoalAgreements(Object.values(data.agreements));
        }
        setUserType(data.user_type);
        setGoalUsers(data.users);
        setUserProfile(data)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const deleteGoal = (id) => {
    axios({
      method: "delete",
      url: `https://api.qonvr.co/api/goals/delete/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response
        console.log(data)
        getGoals(userProfile?.agreements[0].id)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getGoals = (agreementId) => {
    setIsLoading(true)
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/goals/list/${agreementId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data[0];
        setGoalDetails(Object.values(data.goals));
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const updateGoal = (goalId) => {
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/goals/update/${goalId}`,
      crossDomain: true,
      data: goalPopupDetails,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
              // get goals again
    getGoals(userProfile?.agreements[0].id)
      })

  }

  useEffect(() => {
    getUserProfile();
    return () => {};
  }, []);

  console.log(goalUsers.filter(status => status.status_active === 0))
  return (
    <>
      <div className="mt-12">
        <h2 className="text-4xl font-medium pb-4 text-slate-900">Goals</h2>
        {userType === "endUser" && 
          <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400" 
            disabled={userProfile?.agreements[0]?.status_label === "Pending" ? true : false} onClick={(e) => {
              setAddGoalOpen(!addGoalOpen)
          }}>Add new goal</button>}

          {userProfile?.agreements[0]?.status_label === "Pending" ? <Alert severity="warning" className="mt-8">Your have a pending agreement</Alert> : 
        <div className="grid grid-cols-12 gap-6 mt-12">
        {isLoading ? <div className="col-span-12"><Loading text="Loading up your goals..." /></div> :
        <>{userType === "manager" &&
          <div className="col-span-12">
              {goalUsers?.map((user) => {
                if(user.status_active !== 0) {
                      return (
                  <>

                      <div
                        className="bg-white border rounded-lg p-5 mb-5"
                        key={user.id}
                      >
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="w-full flex items-center justify-between">
                                <span className="font-medium">
                                  {user.firstname}
                                </span>
                                <ChevronRightIcon className={open ? 'w-5 rotate-90 transform' : 'w-5'} />
                              </Disclosure.Button>

                              <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel className="mt-4 pt-4">
                                  {user.goals?.length <= 0 && 'No goals setup yet'}
                                  {user.goals?.map((item, i) => {
                                    return (
                                      <div className="rounded-lg bg-gray-100 p-5 mb-3" key={i}>
                                        <p className="font-medium text-sm flex items-center mb-3">
                                          <div
                                            className={`w-4 h-4 rounded-full mr-2 ${
                                              item.status_label ===
                                                GOALS_STATUS.IN_PROGRESS &&
                                              "bg-green-400"
                                            } ${
                                              item.status_label ===
                                                GOALS_STATUS.ARCHIVED &&
                                              "bg-blue-400"
                                            } ${
                                              item.status_label ===
                                                GOALS_STATUS.PAUSED &&
                                              "bg-gray-400"
                                            } ${
                                              item.status_label ===
                                                GOALS_STATUS.RESET &&
                                              "bg-orange-400"
                                            }
                                            `}
                                          ></div>
                                          {item.name}
                                        </p>
                                        <p className="text-sm mb-4">
                                          <FontAwesomeIcon
                                            className="mr-2 text-md "
                                            icon={faCalendarCheck as IconProp}
                                          />
                                          Due by {item.completion_date}
                                        </p>
                                        <p className="text-sm">
                                          {item.description}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                  
                  </>
                );
                } else {
                  return (
                  <p key={user.id}>No active user available</p>)
                }
            
              })}
          </div>}
          {userType === "endUser" && <>
            {goalDetails.length > 0 ? goalDetails?.map((goal: IGoal) => {
             return (
                <div
                  key={goal.agreement_id}
                  className="col-span-12 sm:col-span-4 lg:col-span-3"
                >
                  <div className="rounded-md shadow rounded-md  p-5 bg-white h-full">
                    <img src="/goal.png" alt="" className="w-48 mb-5 mx-auto" />
                    <p className="text-sm">{goal.completion_date}</p>
                    <p className="text-xl font-medium mb-2 mt-2">
                      {goal.name}
                    </p>
                    <p className="text-sm text-gray-500 mb-2">
                      {goal.description}
                    </p>
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium mb-8 ${
                        goal.status_label === GOALS_STATUS.IN_PROGRESS &&
                        "bg-green-100 text-green-800"
                      } ${
                        goal.status_label === GOALS_STATUS.ARCHIVED &&
                        "bg-blue-100 text-blue-800"
                      } ${
                        goal.status_label === GOALS_STATUS.PAUSED &&
                        "bg-gray-100 text-gray-800"
                      } ${
                        goal.status_label === GOALS_STATUS.RESET &&
                        "bg-orange-100 text-orange-800"
                      } mb-8`}
                    >
                      <svg
                        className={`-ml-0.5 mr-1.5 h-2 w-2 ${
                          goal.status_label === GOALS_STATUS.IN_PROGRESS &&
                          "text-green-400"
                        } ${
                          goal.status_label === GOALS_STATUS.ARCHIVED &&
                          "text-blue-400"
                        } ${
                          goal.status_label === GOALS_STATUS.PAUSED &&
                          "text-gray-400"
                        } ${
                          goal.status_label === GOALS_STATUS.RESET &&
                          "text-orange-800"
                        }
                        `}
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                      {goal.status_label}
                    </span>
                    <p className="text-xs font-medium text-gray-600 tracking-wide uppercase mb-2 border-t-2 border-gray-200 pt-5">
                      Actions
                    </p>
                    <ul className="divide-y divide-gray-200 mb-8">
                      {goal.steps?.map((item) => (
                        <li key={item.title} className="py-2">
                          <p className="text-sm text-gray-500">{item.title}</p>
                        </li>
                      ))}
                    </ul>
                    {goal.status_label === "Archived" ? (
                      <button
                        type="button"
                        className="items-center inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-slate-400"
                        disabled
                      >
                        Goal is archived
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="items-center inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={(e) => {
                          setIsOpen(true);
                          setGoalPopupDetails({
                            ...goalPopupDetails,
                            name: goal.name,
                            description: goal.description,
                            completion_date: goal.completion_date,
                          });
                          setCurrentSelectedGoalId(goal.id)
                        }}
                      >
                        Edit
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faPenToSquare as IconProp}
                        />
                      </button>
                    )}
                  </div>
                </div>
              )
            })
            : 
            <div className="col-span-12"><p>No goals are available</p></div>
            }</>
            }
          </>
          }
        </div>
        }
      </div>
      
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setIsOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-blue-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Update your goal
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-blue-300">
                            Update your goal details below
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="relative py-6 px-4 sm:px-6">
                          <div className="mb-3">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Title
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="title"
                                id="title"
                                className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                value={goalPopupDetails?.name}
                                onChange={e => setGoalPopupDetails({...goalPopupDetails, name: e.currentTarget.value})}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="comment"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Goal description
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={4}
                                name="comment"
                                id="comment"
                                className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                value={goalPopupDetails?.description}
                                onChange={e => setGoalPopupDetails({...goalPopupDetails, description: e.currentTarget.value})}
                              />
                            </div>
                          </div>
                          <div className="mb-5">
                            <label
                              htmlFor="date"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Due date
                            </label>
                            <div className="mt-1">
                              <input
                                type="date"
                                name="date"
                                id="date"
                                className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                aria-describedby="date"
                                value={goalPopupDetails?.completion_date}
                                onChange={e => setGoalPopupDetails({...goalPopupDetails, completion_date: e.currentTarget.value})}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button> <button
                       type="button"
                       className="ml-4 bg-red-800 rounded-md border border-red-900 bg-white py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        deleteGoal(currentSelectedGoalId)
                        setIsOpen(false)
                        }}>Delete</button>
                      <button
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => {
                          updateGoal(currentSelectedGoalId)
                          setIsOpen(false)}}
                      >
                        Save
                      </button>
                     
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <AddGoal isOpen={addGoalOpen} popupFunction={setAddGoalOpen} updateGoal={updateGoal}/>
    </>
  );
};

export default GoalsPage;
