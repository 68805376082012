import { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import {
  CogIcon,
  KeyIcon,
  UserCircleIcon,
  ClipboardCheckIcon,
  LightBulbIcon,
  ArrowNarrowRightIcon,
  CheckIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { IUser, IUserProfile } from "../interface/generic";
import { Alert, Snackbar } from "@mui/material";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";
import Loading from "./loading";
import { tiers } from "../control/general";
import { Popover, Dialog } from "@headlessui/react";
const axios = require("axios");

export interface IProps {
  title?: string;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Profile = (props: IProps) => {
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPlans, setIsOpenPlans] = useState(false);
  const [userProfileData, setUserProfileData] = useState<IUser>();
  const [showToast, setShowToast] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [multipleErrorMsg, setMultipleErrorMsg] = useState([]);
  const [passDetails, setPassDetails] = useState({
    old_password: null,
    password: null,
    confirm_password: null,
  });
  const [isSuccess, setIsSuccess] = useState({
    title: "",
    description: "",
  });

  const [convPlanDetails, setConvPlanDetails] = useState({
    description: "",
    goal_check_in_time: "",
    goal_reflection_time: "",
    goal_review_time: "",
    id: null,
    image_url: "",
    list_features: "",
    name: "",
  });

  const [convFreq, setConvFreq] = useState({
    goal_check_in_time: null,
    goal_reflection_time: null,
    goal_review_time: null,
  });

  const subNavigation = [
    {
      name: "Profile",
      href: "#",
      icon: UserCircleIcon,
      current: true,
      managerOnly: false,
    },
    {
      name: "Account",
      href: "#",
      icon: CogIcon,
      current: false,
      managerOnly: false,
    },
    {
      name: "Password",
      href: "#",
      icon: KeyIcon,
      current: false,
      managerOnly: false,
    },
  ];

  const updateUserProfile = () => {
    setIsLoading(true);
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/update/profile`,
      crossDomain: true,
      data: userProfileData,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        //console.log(response);
        setShowToast(true);
        setIsLoading(false);
        setIsSuccess({
          ...isSuccess,
          title: "Success",
          description: "Profile updated",
        });
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setIsLoading(false);
      });
  };

  const updateUserPassword = () => {
    setIsLoading(true);
    if (
      passDetails.confirm_password ||
      passDetails.old_password ||
      passDetails.password
    ) {
      axios({
        method: "put",
        url: `https://api.qonvr.co/api/update/password`,
        crossDomain: true,
        data: passDetails,
        headers: {
          'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
        },
      })
        .then(function (response) {
          //console.log(response);
          setShowToast(true);
          setIsError(false);
          setIsLoading(false);
          setIsSuccess({
            ...isSuccess,
            title: "Success",
            description: "Profile updated",
          });
        })
        .catch(function (error) {
          // handle error
          console.error(error);
          setIsError(true);
          setIsLoading(false);
          if (error.response.data.message) {
            setErrorMsg(error.response.data.message);
          } else {
            setMultipleErrorMsg(error.response.data.errors);
          }
        });
    } else {
      setIsError(true);
      setErrorMsg("Passwords cannot be empty");
      setIsLoading(false);
    }
  };

  const getOrgDetails = (orgId) => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/organisations/detail/${orgId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.organisation;

        setConvFreq({
          ...convFreq,
          goal_check_in_time: data.agreement_plan.goal_check_in_time,
          goal_reflection_time: data.agreement_plan.goal_reflection_time,
          goal_review_time: data.agreement_plan.goal_review_time,
        });
        setConvPlanDetails(data.agreement_plan);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setIsLoading(false);
      });
  };

  const getUserProfile = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        console.log(response.data.profile);
        getOrgDetails(response.data.profile.organisation.id);
        // getConversationList(response.data.profile.agreements[0].id);
        setUserProfileData({
          firstname: response.data.profile.firstname,
          lastname: response.data.profile.lastname,
          job_title: response.data.profile.job_title,
          image_url: response.data.profile.image_url,
          user_type: response.data.profile.user_type,
          organisation: response.data.profile.organisation,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setIsLoading(false);
      });
  };

  const resetAgreement = () => {
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/onboarding`,
      crossDomain: true,
      data: { status_onboarding: false },
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        localStorage.clear();
        window.location.reload();
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUserProfileData({ ...userProfileData, [name]: value });
  };

  const handleOnChangePwd = (event) => {
    const { name, value } = event.target;
    setPassDetails({ ...passDetails, [name]: value });
  };

  const encodeImageFileAsURL = (element) => {
    var file = element.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setUserProfileData({
        ...userProfileData,
        image_url: reader.result.toString(),
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="mt-12">
       <h2 className="text-4xl font-medium pb-4 text-slate-900 mb-10">Settings</h2>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="w-full fixed "
      >
        <div className="fixed w-full left-0 ">
          <Alert variant="filled" severity="success">
            {isSuccess.description}
          </Alert>
        </div>
      </Snackbar>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-7 bg-white rounded-lg shadow overflow-hidden">
          <Tab.Group>
            <div className="lg:grid lg:grid-cols-12">
              <div className="col-span-12 bg-blue-100">
       
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Tab.List className="flex flex-row">
                      {subNavigation
                        .filter((item) => item.managerOnly === false)
                        .map((item) => (
                          <Tab as={Fragment} key={item.name}>
                            {({ selected }) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  selected
                                    ? "bg-white border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700"
                                    : "border-transparent text-gray-900  hover:text-blue-900",
                                  "group border-t-4 px-5 py-2 flex items-center text-sm font-medium"
                                )}
                                aria-current={selected ? "page" : undefined}
                              >
                                <item.icon
                                  className={classNames(
                                    selected
                                      ? "text-blue-500 group-hover:text-blue-500"
                                      : "text-gray-400 group-hover:text-gray-500",
                                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="truncate">{item.name}</span>
                              </a>
                            )}
                          </Tab>
                        ))}
                    </Tab.List>
                  )}
           
              </div>

              <div className="col-span-12">
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Tab.Panels>
                      <Tab.Panel>
                        {/* Profile section */}

                        <div>
                          <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Profile
                          </h2>
                        </div>

                        {/* <div className="mt-6 flex flex-col lg:flex-row">
                      <div className="mt-6 flex-grow lg:mt-0 lg:flex-grow-0 lg:flex-shrink-0">
                        <p
                          className="text-sm font-medium text-gray-700"
                          aria-hidden="true"
                        >
                          Photo
                        </p>
                        <div className="mt-1 lg:hidden">
                          <div className="flex items-center">
                            <div
                              className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                              aria-hidden="true"
                            >
                              <img
                                className="rounded-full h-full w-full"
                                src={userProfileData?.image_url}
                                alt=""
                              />
                              <span className="absolute inset-0 shadow-inner rounded-full bg-gray-400" aria-hidden="true"></span>
                            </div>
                            <div className="ml-5 rounded-md shadow">
                              <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                                <label
                                  htmlFor="mobile-user-photo"
                                  className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
                                >
                                  <span>Change</span>
                                  <span className="sr-only"> user photo</span>
                                </label>
                                <input
                                  id="mobile-user-photo"
                                  name="image_url"
                                  type="file"
                                  onChange={(e) => encodeImageFileAsURL(e.target)}
                                  className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="hidden relative rounded-full overflow-hidden lg:block">
                          <img
                            className="relative rounded-full w-40 h-40"
                            src={userProfileData?.image_url}
                            alt=""
                          />
                          <label
                            htmlFor="desktop-user-photo"
                            className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                          >
                            <span>Change</span>
                            <span className="sr-only"> user photo</span>
                            <input
                              type="file"
                              name="image_url"
                              onChange={(e) => encodeImageFileAsURL(e.target)}
                              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                            />
                          </label>
                        </div>
                      </div>
                    </div> */}

                        <div className="mt-6 grid grid-cols-12 gap-6">
                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="firstname"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              id="firstname"
                              autoComplete="given-name"
                              value={userProfileData?.firstname}
                              onChange={handleOnChange}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="lastname"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              autoComplete="family-name"
                              value={userProfileData?.lastname}
                              onChange={handleOnChange}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="border-t divide-y divide-gray-200 mt-10">
                          <div className="py-4 flex">
                            <button
                              onClick={(e) => updateUserProfile()}
                              className="bg-sky-700 border border-transparent rounded-md shadow py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel>
                        <div>
                          <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Account
                          </h2>
                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="job_title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Your role
                            </label>
                            <input
                              type="text"
                              name="job_title"
                              id="job_title"
                              autoComplete="given-name"
                              value={userProfileData?.job_title}
                              onChange={handleOnChange}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="organisation"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Organisation
                            </label>
                            <input
                              type="text"
                              name="organisation"
                              id="organisation"
                              autoComplete="organization"
                              value={userProfileData?.organisation.name}
                              onChange={handleOnChange}
                              disabled
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm disabled:bg-grey-300"
                            />
                          </div>
                        </div>
                        <div className="border-t divide-y divide-gray-200 mt-10">
                          <div className="py-4 flex ">
                            <button
                              onClick={(e) => updateUserProfile()}
                              className="bg-sky-700 border border-transparent rounded-md shadow py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel>
                        <div>
                          <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Password
                          </h2>
                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="current-pass"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current password
                            </label>
                            <input
                              type="password"
                              name="old_password"
                              onChange={handleOnChangePwd}
                              id="current-pass"
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="new-pass"
                              className="block text-sm font-medium text-gray-700"
                            >
                              New password
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="new-pass"
                              onChange={handleOnChangePwd}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-12 sm:col-span-6">
                            <label
                              htmlFor="confirm-new-pass"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Confirm new password
                            </label>
                            <input
                              type="password"
                              name="confirm_password"
                              id="confirm-new-pass"
                              onChange={handleOnChangePwd}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="border-t mt-10">
                          {isError && (
                            <div className="rounded-md bg-red-50 p-4 mt-5">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <XCircleIcon
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3">
                                  <div className="text-sm font-medium text-red-800">
                                    {errorMsg && <p>{errorMsg}</p>}
                                    {Object.values(multipleErrorMsg).map(
                                      (e) => {
                                        return <p>{e}</p>;
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className="ml-auto pl-3">
                                  <div className="-mx-1.5 -my-1.5">
                                    <button
                                      type="button"
                                      onClick={(e) => setIsError(false)}
                                      className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                    >
                                      <span className="sr-only">Dismiss</span>
                                      <XIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="py-4 flex">
                            <button
                              onClick={(e) => updateUserPassword()}
                              className="bg-sky-700 border border-transparent rounded-md shadow py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            >
                              Update password
                            </button>
                          </div>
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  )}
                </div>
              </div>
            </div>
          </Tab.Group>
        </div>
        <div className="col-span-12 lg:col-span-5 bg-white rounded-lg shadow overflow-hidden">
          <div className="p-6 grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="pb-4 ">
                <p className="text-base text-gray-500 mb-2">
                  Your organisation plan
                </p>
                <p className="font-medium mb-2">
                  <span className="bg-grey-200 uppercase">
                    {userProfileData &&
                    userProfileData?.organisation.plan_selected === "endUser"
                      ? "User selected plan"
                      : "Manager selected"}
                  </span>
                </p>
              </div>
              {userProfileData?.organisation.plan_selected === "manager" && (
                <>
                  <div className="pb-4">
                    <p className="text-base text-gray-500">Cadence</p>
                    <p className="font-medium mb-2">
                      <button className="mt-2 flex bg-blue-100 rounded-full px-3 py-1 content-center items-center hover:bg-blue-300 transition" onClick={(e) => setIsOpenPlans(true)}>
                        <span>{convPlanDetails && convPlanDetails?.name}</span> <ChevronRightIcon className="w-5" />
                      </button>
                    </p>
                    <p className="text-base">
                      {convPlanDetails && convPlanDetails?.description}
                    </p>
                  </div>
                  <div className="py-4">
                    <p className="text-base text-gray-500">Check in</p>
                    <p className="text-base">
                      {convPlanDetails &&
                        convPlanDetails.goal_check_in_time.replace("|", " ")}
                    </p>
                  </div>
                  <div className="py-4">
                    <p className="text-base text-gray-500">Reflection</p>
                    <p className="text-base">
                      {convPlanDetails &&
                        convPlanDetails?.goal_reflection_time.replace("|", " ")}
                    </p>
                  </div>
                  <div className="py-4">
                    <p className="text-base text-gray-500">Goal Setting</p>
                    <p className="text-base">Once</p>
                  </div>
                </>
              )}
            </div>
            {userProfileData?.user_type === "manager" && (
              <div className="col-span-12">
                <div className="rounded bg-orange-100 p-5 h-full">
                  <p className="font-medium mb-3">
                    Would you like to reset your agreement?
                  </p>
                  <p className="mb-3">
                    Resetting the Agreement means you can select a new plan for
                    your team.
                  </p>
                  <p className="mb-8">
                    Current goals and conversation data will be archived and
                    accessible. However all current or new team members will
                    have to go through onboarding to agree to the new cadence.
                  </p>
                  <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Reset Agreement
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-5">
            <Dialog.Title className="text-2xl mb-3">
              Reset Agreement
            </Dialog.Title>
            <Dialog.Description className="font-medium mb-3">
              Please confirm before you reset the agreement.
            </Dialog.Description>

            <p className="mb-3">
              Resetting the Agreement means you can select a new plan for your
              team.
            </p>
            <p className="mb-5">
              Current goals and conversation data will be archived and
              accessible. However all current or new team members will have to
              go through onboarding to agree to the new cadence.
            </p>

            <button
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={() => resetAgreement()}
            >
              Reset My Agreement
            </button>
            <button
              className="inline-flex justify-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
      <Dialog open={isOpenPlans} onClose={() => setIsOpenPlans(false)} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel  className="w-full container rounded bg-white p-8 relative">
          <button onClick={e => setIsOpenPlans(false)} className="top-4 right-4 absolute"><XIcon className="h-6 w-6 text-black" aria-hidden="true" /></button>
          <Dialog.Title className="text-2xl font-medium">View plans</Dialog.Title>
          <p>Development Cadence for your whole team</p>
          <div className="grid grid-cols-12 gap-6 mt-8">
            <div className="col-span-12 lg:col-span-12 ">
              <div className="mb-5 space-y-4  sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                {tiers.map((tier) => (
                  <div key={tier.title} className="relative">
                    <div
                      key={tier.title}
                      className={`bg-slate-100 relative  ${convPlanDetails?.name.toLowerCase() === tier.title.toLowerCase() ? 'bg-green-300 rounded-t-md' : 'rounded-md'}`}
                    >
                      <div className="p-6 pb-4 pt-6">
                        <h2 className="text-2xl leading-6 font-medium text-black">
                          {tier.title}
                        </h2>
                        <p className="mt-5 text-sm text-black h-16">
                          {tier.description}
                        </p>
                      </div>
                      <div className="pt-2 px-6">
                        <h3 className="text-xs font-medium text-black tracking-wide uppercase">
                          What's included
                        </h3>
                        <ul className="mt-6 space-y-4">
                          {tier?.inclusions?.map((feature) => (
                            <li key={feature} className="flex space-x-3">
                              <CheckIcon
                                className="flex-shrink-0 h-5 w-5 text-black"
                                aria-hidden="true"
                              />
                              <span className="text-sm text-black">
                                {feature}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pt-6 pb-6 px-6">
                        <Popover className="relative">
                          <Popover.Button className="text-xs font-medium text-gray-900 tracking-wide uppercase p-6 pb-0 pl-0 flex items-center">
                            More details
                            <ArrowNarrowRightIcon
                              className="flex-shrink-0 h-5 w-5 ml-2"
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Popover.Overlay className="opacity-30 fixed inset-0 bg-black" />
                          <Popover.Panel className="absolute z-50 bottom-0 left-32 bg-white shadow w-80 sm:rounded-md  bg-white">
                            <ul className="mt-6 space-y-4 pt-0 p-6">
                              {tier?.moreDetails?.map((details) => (
                                <li key={details} className="flex space-x-3">
                                  <CheckIcon
                                    className="flex-shrink-0 h-5 w-5 text-black"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm text-black">
                                    {details}
                                  </span>
                                </li>
                              ))}
                            </ul>

                            <img src="/solutions.jpg" alt="" />
                          </Popover.Panel>
                        </Popover>
                      </div>
                    </div>
                    {convPlanDetails?.name.toLowerCase() === tier.title.toLowerCase() && <div className="text-center text-white py-3 rounded-b-md bg-green-600">Your current plan</div>}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};
