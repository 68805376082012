import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faCalendarCheck,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faComments,
  faCommentsQuestionCheck,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import { GOALS_STATUS } from "../control/general";
import { IUserProfile } from "../interface/generic";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { ChevronRightIcon } from "@heroicons/react/solid";

export interface IProps {
  title?: string;
}
const axios = require("axios");

const ConversationsPage = (props: IProps) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [convFreq, setConvFreq] = useState({
    goal_check_in_time: null,
    goal_reflection_time: null,
    goal_review_time: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [goalConv, setGoalConv] = useState([]);
  const [userType, setUserType] = useState();
  const [convUsers, setConvUsers] = useState([]);
  const [convPlans, setConvPlans] = useState([]);
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [next, setNext] = useState(6);
  const [convPlanDetails, setConvPlanDetails] = useState({
    description: "",
    goal_check_in_time: "",
    goal_reflection_time: "",
    goal_review_time: "",
    id: null,
    image_url: "",
    list_features: "",
    name: "",
  });

  const handleMoreConv = () => {
    setNext(next + 3);
  };

  const getUserDetail = (id) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/users/detail/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile.agreements[0].agreement_plan_id;
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
    return <></>;
  };

  const getUserProfile = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setUserType(data.user_type);
        setConvUsers(data.users);
        setUserProfile(data);
        getOrgDetails(data.organisation.id);
        data.agreements[0] && getConversationList(data.agreements[0].id);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getOrgDetails = (orgId) => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/organisations/detail/${orgId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.organisation;

        setConvFreq({
          ...convFreq,
          goal_check_in_time: data.agreement_plan.goal_check_in_time,
          goal_reflection_time: data.agreement_plan.goal_reflection_time,
          goal_review_time: data.agreement_plan.goal_review_time,
        });
        setConvPlanDetails(data.agreement_plan);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setIsLoading(false);
      });
  };

  const getConversationList = (id) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversations/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data;
        // //console.log('data is', data.agreement.goal_conversations)
        setConvFreq({
          ...convFreq,
          goal_check_in_time: data.agreement.agreement_plan.goal_check_in_time,
          goal_reflection_time:
            data.agreement.agreement_plan.goal_reflection_time,
          goal_review_time: data.agreement.agreement_plan.goal_review_time,
        });
        setGoalConv(data.agreement.goal_conversations);

      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="mt-12">
      <h2 className="text-4xl font-medium mb-8 pb-4 text-slate-900">
        Conversations
      </h2>

      {userProfile?.agreements[0]?.status_label === "Pending" && (
        <Alert severity="warning" className="my-8">
          You have a pending agreement
        </Alert>
      )}
      {isLoading ? (
        <Loading />
      ) : userType === "manager" ? (
        convUsers?.map((user) => {
          if(user.status_active !== 0) {
            return (
            <div className="bg-white border rounded-lg p-5 mb-5" key={user.id}>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="w-full flex items-center justify-between">
                      <span className="font-medium">{user.firstname}</span>
                      <ChevronRightIcon className={open ? 'w-5 rotate-90 transform' : 'w-5'} />
                    </Disclosure.Button>

                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="mt-2">
                        <div className="grid grid-cols-12 md:gap-12">
                          <div className="col-span-12 md:col-span-6">
                            <p className="my-4 text-xl text-gray-600">
                              <FontAwesomeIcon
                                className="mr-2 text-xl text-blue-500"
                                icon={faComments as IconProp}
                              />
                              Conversation overview
                            </p>

                            <dl className="divide-y divide-gray-200">
                              <div className="pb-4">
                                <p className="text-base text-gray-500">
                                  Cadence
                                </p>
                                <p className="font-medium mb-2">
                                  {convPlanDetails && convPlanDetails.name}
                                </p>
                                <p className="text-base">
                                  {convPlanDetails &&
                                    convPlanDetails.description}
                                </p>
                              </div>
                              <div className="py-4">
                                <p className="text-base text-gray-500">
                                  Goal Setting
                                </p>
                                <p className="text-base">
                                 Once
                                </p>
                              </div>
                              <div className="py-4">
                                <p className="text-base text-gray-500">
                                  Check in
                                </p>
                                <p className="text-base">
                                  <span className="mr-1">Every</span>
                                  {convPlanDetails &&
                                    convPlanDetails.goal_check_in_time.replace(
                                      "|",
                                      " "
                                    )}
                                </p>
                              </div>
                              <div className="py-4">
                                <p className="text-base text-gray-500">
                                  Reflection
                                </p>
                                <p className="text-base">
                                <span className="mr-1">Every</span>
                                  {convPlanDetails &&
                                    convPlanDetails.goal_reflection_time.replace(
                                      "|",
                                      " "
                                    )}
                                </p>
                              </div>

                            </dl>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <p className="my-4 text-xl text-gray-600">
                              <FontAwesomeIcon
                                className="mr-2 text-xl text-blue-500"
                                icon={faCommentsQuestionCheck as IconProp}
                              />
                              Upcoming conversation
                            </p>

                            <div className="divide-y divide-gray-200">
                              {user.goal_conversations.length <= 0 && 'Goal Setting - Date not proposed yet ' + user.firstname + ' to accept your Growth Agreement to start setting goals'}
                              {user.goal_conversations
                                .slice(0, 10)
                                .map((item) => {
                                  return (
                                    <div className="py-3 flex items-center justify-between">
                                      <Link
                                        to={
                                          (item.type === "g" &&
                                            "/conversation-goal-setting-manager") ||
                                          (item.type === "rv" &&
                                            "/conversation-goal-setting-manager") ||
                                          (item.type === "c" &&
                                            "/conversation-checkin-manager") ||
                                          (item.type === "r" &&
                                            "/conversation-reflection-manager")
                                        }
                                        state={{
                                          data: item.date,
                                          status: item.status_input,
                                          id: item.agreement_id,
                                          isManager: true,
                                          name: user.firstname
                                        }}
                                        className={`flex p-3 mb-2 rounded-sm w-full justify-between ${item.status === 1 ? 'bg-green-200 text-green-700' : 'bg-gray-100' }`}
                                      >
                                        <p>
                                          {(item.type === "g" &&
                                            "Goal Setting") ||
                                            (item.type === "rv" &&
                                              "Goal Review") ||
                                            (item.type === "c" && "Check in") ||
                                            (item.type === "r" && "Reflection")}
                                        </p>
                                        <p>
                                          <FontAwesomeIcon
                                            className="mr-2 text-xl"
                                            icon={faCalendarCheck as IconProp}
                                          />
                                          {new Date(
                                            item.date
                                          ).toLocaleDateString("en-AU")}
                                        </p>
                                      </Link>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          );
          }
          return (
            <><p>No active user available</p></>
          )
          
        })
      ) : (
        <>
          <h3 className="text-xl leading-6 font-medium text-gray-600 mb-5">
            Your cadence overview
          </h3>
          <div className="shadow rounded-md bg-white p-5 h-full mb-12">
            <div className="grid grid-cols-12 gap-6 ">
              <div className="col-span-12 lg:col-span-4">
                <div className="border-l-4 pl-3 mb-3">
                  <p className="text-gray-400">Goals Setting</p>
                  <p className="text-2xl font-medium">
                   Once
                  </p>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="border-l-4 pl-3 mb-3">
                  <p className="text-gray-400">Goals Check-in</p>
                  <p className="text-2xl font-medium">
                    {convFreq.goal_check_in_time &&
                      (convFreq?.goal_check_in_time).replace("|", " ")}
                  </p>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="border-l-4 pl-3 mb-3">
                  <p className="text-gray-400">Goals Reflection</p>
                  <p className="text-2xl font-medium">
                    {convFreq.goal_reflection_time &&
                      (convFreq?.goal_reflection_time).replace("|", " ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h3 className="text-xl leading-6 font-medium text-gray-600 mb-5">
            Conversation overview
          </h3>
          {goalConv.length <= 0 && (
            <Alert severity="info">No conversation details yet</Alert>
          )}
          <div className="grid grid-cols-12 gap-6 mb-8">
            {goalConv.slice(0, next).map((item) => {
              if (item.type === "g") {
                return (
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150">
                    <div
                      className={`shadow rounded-md ${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      } p-5 h-full`}
                    >
                      <div className="flex justify-between">
                        <h4 className="text-lg border-l-4 pl-3 mb-3">
                          Goal Setting
                        </h4>
                        {item.status_input === 1 && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>
                      <img
                        src="/set-goal.png"
                        alt="Goal Setting"
                        className="mb-3 mx-auto h-36"
                      />
                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>
                      <p className="text-sm">
                        This is about discussing your needs and setting your
                        development objectives and targets as well as clarifying
                        expectations.
                      </p>
                      <Link
                        to="/conversation-goal-setting"
                        className="mt-8 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        state={{
                          data: item.date,
                          status: item.status_input,
                          id: item.agreement_id,
                        }}
                      >
                        {item.status_input === 1 ? `View ` : `Start `}
                        conversation
                      </Link>
                    </div>
                  </div>
                );
              }
              if (item.type === "c") {
                return (
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150">
                    <div
                      className={`shadow rounded-md p-5 h-full ${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      }`}
                    >
                      <div className="flex justify-between">
                        <h4
                          className={`text-lg border-l-4 pl-3 mb-3 ${
                            item.status_input === 1 &&
                            `border-green-500 text-green-800`
                          }`}
                        >
                          Check-in
                        </h4>
                        {item.status_input === 1 && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>
                      <img
                        src="/discuss-goal-conv.png"
                        alt="Goal Setting"
                        className="mb-3 mx-auto h-36"
                      />
                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>
                      <p className="text-sm">
                        This is about setting the frequent development check-ins
                        and tracking your progress.
                      </p>
                      <Link
                        to="/conversation-checkin"
                        className="mt-8 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        state={{
                          data: item.date,
                          status: item.status_input,
                          id: item.agreement_id,
                        }}
                      >
                        {item.status_input === 1 ? `View ` : `Start `}
                        conversation
                      </Link>
                    </div>
                  </div>
                );
              }
              if (item.type === "r") {
                return (
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150">
                    <div
                      className={`shadow rounded-md p-5 h-full ${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      }`}
                    >
                      <div className="flex justify-between">
                        <h4
                          className={`text-lg border-l-4 pl-3 mb-3 ${
                            item.status_input === "1" &&
                            `border-green-500 text-green-800`
                          }`}
                        >
                          Reflection
                        </h4>
                        {item.status_input === "1" && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>
                      <img
                        src="/reflection-manager.png"
                        alt="Goal Setting"
                        className="mb-3 mx-auto h-36"
                      />
                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>
                      <p className="text-sm">
                        This is about gathering feedback to reflect on your
                        progress and reviewing your development goals.
                      </p>
                      <Link
                        to="/conversation-reflection"
                        className="mt-8 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        state={{
                          data: item.date,
                          status: item.status_input,
                          id: item.agreement_id,
                        }}
                      >
                        {item.status_input === "1" ? `View ` : `Start `}
                        conversation
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {goalConv.length > 4 &&
          <div className="p-6 mt-8 mb-8 bg-slate-200 rounded-md flex  justify-center">
            <button
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleMoreConv}
            >
              <FontAwesomeIcon
                className="mr-2"
                icon={faPlus as IconProp}
              />
              More conversations
            </button>
          </div>
}
        </>
      )}
    </div>
  );
};

export default ConversationsPage;
