import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseyeArrow, faLightbulbOn, faUser, faUserPlus, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "./loading";
import { faUserAlien, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { faClock, faCheckCircle, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";

export interface IProps {
  details?: any;
  isLoading?: boolean;
  agreementStatus?: string;
}

const Users = (props: IProps) => {
  console.log(props)
  return (
    <>
    {props.isLoading ? <Loading /> : 
    <div className="shadow sm:rounded-md mb-6 bg-white h-full overflow-hidden">
      <div className="flex p-6">
        <FontAwesomeIcon
          icon={faUsers as IconProp}
          className="text-2xl text-gray-500"
        />
        <h2
          id="timeline-title"
          className="text-xl text-gray-900 ml-3 font-medium"
        >
          Members
        </h2>
      </div>
      {props.details ? <ul className="divide-y divide-gray-200">
        {props.details?.map((item, i) => {
          return (
            <li className="px-6 py-4 flex items-center sm:px-6" key={i}>
              <div className="flex items-center">
                <div className="rounded-full w-10 h-10 mr-2 flex items-center justify-center bg-gray-100">
                <FontAwesomeIcon
                  icon={faUser as IconProp}
                  className="text-xl text-gray-500 "
                /></div>
                <div>
                  <p className="font-medium text-gray-600 mb-1">{item.firstname}</p>
                  {item.status_onboarding ? <p className="text-sm font-medium text-gray-400"><Link to="/agreement">Agreement</Link></p> : <p className="text-sm font-medium text-gray-400">Account currently inactive</p>}
                </div>
              </div>
            </li>
          );
        })}
      </ul> : <p className="mx-6">You have no members yet.</p>
      }<div className="px-6 pt-6">
      <div className="bg-blue-100 text-sm text-slate-900 rounded-sm px-4 py-3 w-full"> <FontAwesomeIcon
                  icon={faInfoCircle as IconProp}
                  className="text-slate-900 mr-2 "
                />How to add new member? <Link to='/manage-users' className="font-medium">click here</Link></div></div>
    </div>
}
  </>
  );
};

export default Users;
