import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import {
  faArrowRight,
  faArrowLeft,
  faLightbulbOn,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Alert, setRef, Snackbar } from "@mui/material";
import { IReflection } from "../interface/generic";
import Loading from "../components/loading";
import { Link, useLocation } from "react-router-dom";
const axios = require("axios");
const moment = require('moment');
export interface IProps {
  title?: string;
  date?: string;
  isManager?: boolean
}

type LocationProps = {
  state: {
    data: any;
    status: any;
    id: any;
  };
};

const Reflection = (props: IProps) => {
  const { userProfile } = useContext(ProfileContext);
  const [stage, setStage] = useState("start");
  const [conversation, setConversation] = useState("prepare");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [multipleErrorMsg, setMultipleErrorMsg] = useState([]);
  const [reflection, setReflection] = useState<IReflection>();
  const [isViewMode, setIsViewMode] = useState(false)
  const token = localStorage.getItem("token");

  const location = useLocation() as unknown as LocationProps;
  const dateFromConv = location.state?.data
  const convStatus = location.state?.status
  const agreementIdProps = location.state?.id


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        //console.log(data.agreements[0].id)
        setReflection({...reflection, date: dateFromConv, agreement_id: data.agreements[0].id})
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const createReflection = () => {
    setIsLoading(true)
    axios({
      method: "post",
      url: `https://api.qonvr.co/api/conversation/goal-reflection`,
      crossDomain: true,
      data: reflection,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setShowToast(true);
        setIsLoading(false);
        setConversation("action");
        if(error.response.data.message) {
          setErrorMsg(error.response.data.message)
        } else {
          setMultipleErrorMsg(error.response.data.errors)
        }
      });
  }

  const getConversationDetail = (agreementId, date) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversation/goal-reflection/list/${agreementId}/${date}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.data[0];
        setReflection(data)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
       
      });
  }

  useEffect(() => {
    if(convStatus === "1") {
      //console.log('Conversation is view mode')
      getConversationDetail(location.state.id, location.state.data)
      setIsViewMode(true)
    }
  }, [])

  return (
    <>
     <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="w-full fixed mt-5"
      >
        <div className="fixed w-full left-0 ">
          <Alert variant="filled" severity="error" className="m-5">
            {errorMsg || Object.values(multipleErrorMsg).map((e) =>{ return(<p key={e}>{e}</p>)})}
          </Alert>
        </div>
      </Snackbar>
      {stage === "start" && (
        <div className="mt-16">
          <div className="mb-16">
            <h1 className="text-5xl font-medium text-gray-900">
              Hey <strong>{userProfile.firstname}</strong>
            </h1>
            <p className="mt-4 mb-10 text-2xl">
              We will be stepping through these <span className="font-medium ms-1">steps</span>
              <br />
              for a more constructive check-in conversation
            </p>
            <div className="grid grid-cols-12 gap-20">
              <div className="col-span-3 h-full">
              <div className="h-48 flex content-end"><img src="/plan-goal-conv.png" alt="" /></div>
                <div className="mt-5">
                  <span className="text-4xl">1</span>
                  <h2 className="font-medium">Prepare</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Prepare beforehand. Spend some time to review past check-in
                    conversations
                  </p>
                </div>
              </div>
              <div className="col-span-3 h-full">
              <div className="h-48 flex content-end"><img src="/discuss-goal-conv.png" alt="" /></div>
                <div className="mt-5">
                  <span className="text-4xl">2</span>
                  <h2 className="font-medium">Discuss</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Have a discussion about your overall progress so far
                  </p>
                </div>
              </div>
              <div className="col-span-3 h-full">
              <div className="h-48 flex content-end"><img src="/set-goal-conv.png" alt="" /></div>
                <div className="mt-5">
                  <span className="text-4xl">3</span>
                  <h2 className="font-medium">Agree</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Agree and set what to do more of, what to do less of
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-10"
            onClick={(e) => setStage("conversations")}
          >
            Let's start the conversation
            <FontAwesomeIcon className="ml-2" icon={faArrowRight as IconProp} />
          </button>
        </div>
      )}
      {stage === "conversations" && (
        <div className="mt-16">
        <h2 className="text-3xl font-medium border-b-2 pb-4 mb-8">Reflection conversation</h2>
          {conversation === "prepare" && (
            <div className="mt-16">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 rounded-lg overflow-hidden  shadow bg-white p-6">
                  
                  <img src="/plan-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    If you have forgotten, <Link to="/conversations">click here</Link> to review all past
                    check-in conversations
                  </Alert>
                </div>
                <div className="col-span-9 p-4">
                  <h2 className="font-medium mb-4 text-2xl">
                    In terms of your overall development progress, what has
                    worked or helping so far?
                  </h2>
                  <p className="mb-8 text-gray-500">
                    Discuss and write down the positives and the things you that
                    has helped or enabled you to progress
                  </p>
                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    value={reflection?.has_worked_or_helping || "" }
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8 disabled:bg-gray-100 disabled:shadow-none"
                    placeholder=""
                    onChange={(e) => setReflection({...reflection, has_worked_or_helping: e.target.value})}
                    disabled={isViewMode || props.isManager}
                  />
                 
                  <div className="flex justify-end mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                      onClick={(e) => setConversation("discuss")}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {conversation === "discuss" && (
            <div className="mt-16">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 rounded-lg overflow-hidden  shadow bg-white p-6">
                  
                  <img src="/discuss-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    If you have forgotten, click here to review all past
                    check-in conversations
                  </Alert>
                </div>
                <div className="col-span-9 p-4">
                  <h2 className="font-medium mb-4 text-2xl">
                    In terms of your overall development progress, what has not
                    worked or not helping so far?
                  </h2>
                  <p className="mb-8 text-gray-500">
                    Discuss and write down challenges and the things you that
                    has not helped or not enabled you to progress
                  </p>
                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    value={reflection?.has_not_worked_or_helping || ""}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8 disabled:bg-gray-100 disabled:shadow-none"
                    placeholder=""
                    onChange={(e) => setReflection({...reflection, has_not_worked_or_helping: e.target.value})}
                    disabled={isViewMode || props.isManager}
                  />
                 
                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        setConversation("prepare")
                      }}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        getUserProfile();
                        setConversation("action")
                      }}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {conversation === "action" && (
            <div className="mt-16">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 rounded-lg overflow-hidden  shadow bg-white p-6">
                  
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    Give yourself time to mull over the ideas or opportunities
                    to do more or less of, before implementing them
                  </Alert>
                </div>
                <div className="col-span-9 p-4">
                  <h2 className="font-medium mb-4 text-2xl">
                    Agree on what you should <span className="font-medium ms-1">do more</span> of
                  </h2>

                  <p className="mb-8 text-gray-500">
                    Write down the ideas and opportunities that you should do
                    more of to progress
                  </p>
                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8 disabled:bg-gray-100 disabled:shadow-none"
                    placeholder=""
                    value={reflection?.should_do_more || ""}
                    onChange={(e) => setReflection({...reflection, should_do_more: e.target.value})}
                    disabled={isViewMode || props.isManager}
                  />

                  <h2 className="font-medium mb-4 text-2xl">
                    Agree on what you should <span className="font-medium ms-1">do less</span> of
                  </h2>

                  <p className="mb-8 text-gray-500">
                    Write down the ideas and opportunities that you should do
                    less of to progress
                  </p>
                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8 disabled:bg-gray-100 disabled:shadow-none"
                    placeholder=""
                    value={reflection?.should_do_less || ""}
                    onChange={(e) => setReflection({...reflection, should_do_less: e.target.value})}
                    disabled={isViewMode  || props.isManager}
                  />

                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => setConversation("discuss")}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        if(isViewMode || props.isManager) {
                          setConversation("complete")
                        } else {
                          setConversation("complete")
                          createReflection()
                        }


                      }}
                    >
                      Complete
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {conversation === "complete" && (
            <div className="mt-16">
              {isLoading ?  <Loading text="Loading" /> : 
              <div className="text-center">
                <img src="/set-goal-conv.png" alt="" className="w-64 m-auto" />
                <h1 className="text-3xl mb-3 mt-8">Great!</h1>
                <p className="text-gray-500 mb-8">
                  You have completed your reflection.
                </p>
                <a
                  href="/dashboard"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go to Dashboard
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowRight as IconProp}
                  />
                </a>
              </div>
              }
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Reflection;
