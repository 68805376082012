import { useContext, useEffect, useState } from "react";
import Agreement from "../components/agreement";
import { IAgreement, IConversationFrequency, IOnboardingDeals } from "../interface/generic";
import { faHandsHelping } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { ProfileContext } from "../context/profileProvider";
import Loading from "../components/loading";
import { Dialog } from "@headlessui/react";
import { SettingsPowerRounded } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faLightbulb, faSquareInfo, faUserCheck } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";

import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons'
import { faLink } from '@fortawesome/pro-light-svg-icons'

const axios = require("axios");

export interface IProps {
  title?: string;
}

const AgreementPage = (props: IProps) => {
  const token = localStorage.getItem("token");
  const [listAgreements, setListAgreements] = useState<IAgreement[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const { userProfile } = useContext(ProfileContext);
  const [isOpen, setIsOpen] = useState(false);
  const [conversationFrequency, setConversationFrequency] = useState<IConversationFrequency>()

  const getListAgreements = () => {
    setIsLoading(true)
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/agreement/list`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.agreement;
        setListAgreements(data)
        setIsLoading(false)
        getConversationDetail(data[0].agreement_plan.name)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const processAgreement = (id, status) => {
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/agreement/update/${id}`,
      crossDomain: true,
      data: {
        status: status
      },
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.agreement;
        setListAgreements(data)
        getListAgreements();
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const getOrgDetails = (orgId) => {
    setPlanLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/organisations/detail/${orgId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.organisation;
        setConvPlanDetails(data.agreement_plan);
        setPlanLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setPlanLoading(false);
      });
  }

  const [convPlanDetails, setConvPlanDetails] = useState({
    description: "",
    goal_check_in_time: "",
    goal_reflection_time: "",
    goal_review_time: "",
    id: null,
    image_url: "",
    list_features: "",
    name: "",
  });

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        getOrgDetails(response.data.profile.organisation.id)

      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  useEffect(() => {
    getListAgreements();
    getUserProfile();
  }, []);

  const tiers = [
    {
        id: 1,
        title: 'FOCUSED',
        description: 'A cadence that is great for increasing your development progress with more frequent conversations.',
        iconName: faBullseyePointer,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Monthly',
            'Goals Check-In - Weekly'
        ],
        moreDetails: [
            'Weekly check-ins',
            'Suitable for  development goals with shorter time frames (1 month)',
            'Able to quickly increase engagement and development',
            'Recommended if focus is on a small group of high performers or team needs hand-holding'
        ],
        imageUrl: '/light.png'
    },
    {
        id: 2,
        title: 'FLEXIBLE',
        description: 'A flexible cadence that can accomodate a range of short, mid and long term goals.',
        iconName: faLink,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Once every 2 months',
            'Goals Check-In - Fortnightly'
        ],
        moreDetails: [
            'Fortnightly check-ins',
            'Flexible for a mix of mid to long term development goals',
            'Able to accommodate more members because of less frequent check-ins',
            'Recommended if you want to focus on a group of high performers'
        ],
        imageUrl: '/medium.png'
    },
    {
        id: 3,
        title: 'SUPPORTIVE',
        description: 'A cadence that is suitable for mid to long term goals.  Suitable if you feel you don’t need a lot of handholding.',
        iconName: faHandsHelping,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Once every 3 months',
            'Goals Check-In - Monthly'
        ],
        moreDetails: [
            'Monthly check-ins',
            'Suitable for a mix of mid to long term development goals',
            'Recommended for more self-driven members without frequent check-ins'
        ],
        imageUrl: '/high.png'
    }
  ]

  const getConversationDetail = (title) => {
    tiers.forEach((item) => {
      if(item.title === title) {
        setConversationFrequency(item)
      }
    })
  };


  return (
    <div className="mt-12">
      <h2 className="text-4xl font-medium pb-4 text-slate-900">Agreement</h2>
      {(userProfile.user_type === "manager") && 
      <><span className="bg-yellow-100 text-yellow-900 col p-1 px-2 inline"><FontAwesomeIcon className="mr-2" icon={faSquareInfo as IconProp} />Please note: To change the cadence for all team members, please <Link className="font-medium" to="/profile">Reset</Link> the agreement</span>
        <div className="grid grid-cols-12 gap-8 mt-12">
         
            {isLoading ? <div className="col-span-12 mt-12"><Loading /></div> : <div className="col-span-12">
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
               
                {planLoading ? <Loading /> : convPlanDetails ?
                          <li className="sm:rounded-md mb-6 p-6 bg-gradient-to-r from-cyan-500 to-blue-500 h-full">                     
                            <div className="pb-2">
                              <p className="text-base text-white">Cadence</p>
                              <p className="font-medium mb-2">
                                {convPlanDetails && convPlanDetails?.name}
                              </p>
                              <p className="text-base">
                                {convPlanDetails && convPlanDetails?.description}
                              </p>
                            </div>
                            <div className="py-2">
                              <p className="text-base text-white">
                                Check in
                              </p>
                              <p className="text-base">
                                {convPlanDetails &&
                                  convPlanDetails.goal_check_in_time.replace(
                                    "|",
                                    " "
                                  )}
                              </p>
                            </div>
                            <div className="py-2">
                              <p className="text-base text-white">
                                Reflection
                              </p>
                              <p className="text-base">
                                {convPlanDetails &&
                                  convPlanDetails?.goal_reflection_time.replace(
                                    "|",
                                    " "
                                  )}
                              </p>
                            </div>
                            <div className="py-2">
                              <p className="text-base text-white">
                                Goal Setting
                              </p>
                              <p className="text-base">
                               Once
                              </p>
                            </div>
                        </li> : <li className="bg-white rounded-lg shadow p-6 text-center"><FontAwesomeIcon className="text-6xl mb-4 text-blue-500" icon={faUserCheck as IconProp} /><p>You have selected a <br /><strong>User managed</strong> organisational plan. Each individual team member will have their own plans.</p></li>
                  }
                {listAgreements?.map((agreement) => (
                <li key={agreement?.users[0].email_address} className="flex flex-col justify-between bg-white rounded-lg shadow divide-y divide-gray-200">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <div className="flex items-center space-x-3 mb-5">
                          <h3 className="text-gray-900 text-lg truncate font-medium">
                            {agreement?.users[0].firstname}
                          </h3>
                          <span className={`flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium ${agreement?.status_label === "Reset" && "bg-orange-100 text-orange-800" } ${agreement?.status_label === "Agreed" && "bg-green-100 text-green-800" } ${agreement?.status_label === "Pending" && "bg-orange-100 text-orange-800" } rounded-full`}>
                            {agreement?.status_label === "Reset" ? "Archived" : agreement?.status_label}
                          </span>
                        </div>
                        <p className="text-gray-500 text-sm font-medium">Plan</p>
                        <p className="text-sm mb-3">{agreement?.agreement_plan.name}</p>
                        <p className="text-gray-500 text-sm font-medium">Proposed Goal Setting Date</p>
                        <p className="text-sm mb-3">{new Date(agreement?.date).toLocaleDateString('en-AU')}</p>
                      </div>
                    
                    </div>
                    {agreement.status_label === "Agreed" || agreement.status_label === "Reset" ? null :
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="-ml-px w-0 flex-1 flex">
                          <button
                            onClick={(e) =>{
                              setIsOpen(true)
                              processAgreement(agreement.id, true)}}
                            className="relative w-0 bg-green-500 flex-1 inline-flex items-center justify-center py-4 text-sm text-white font-medium border border-transparent rounded-bl-lg rounded-br-lg hover:text-white hover:bg-green-600"
                          >
                            <CheckIcon
                              className="w-5 h-5 text-white mr-2"
                              aria-hidden="true"
                            />
                            Accept
                          </button>
                        </div>
                        
                      </div>
                    </div>
                }
                </li>
                ))}
                 {listAgreements?.length <= 0 && <li className="bg-white rounded-lg shadow p-6">      <p>No agreements available yet.</p></li>}
              </ul>
            </div>}
          
        </div></>
      }
      {userProfile?.user_type === "endUser" && 
       <Agreement
        conversationFrequency={conversationFrequency}
      /> 
      }
        <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-xl rounded bg-white p-5 text-center">
          <Dialog.Title className='text-2xl font-medium mb-3'>Great!</Dialog.Title>
          <Dialog.Description className='mb-8'>
          You have accepted the Agreement
          </Dialog.Description>
            <button className="mb-2 inline-flex justify-center items-center rounded-md border border-transparent bg-green-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick={() => { 
              setIsOpen(false)
            }}>Done</button>
          </Dialog.Panel>
        </div>
    </Dialog>
    </div>
  );
};

export default AgreementPage;
