import { faCommentsAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowNarrowRightIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "../components/loading";
import { Disclosure } from "@headlessui/react";

export interface IProps {
  title?: string;
  description?: string;
  isManager?: boolean;
  goalConv?: any;
  agreementStatus?: string;
  isLoading?: boolean;
}

const UpcomingConversationWidget = (props: IProps) => {
  return (
    <>
      <div className="shadow sm:rounded-md mb-6 bg-white h-full">
        <div className="flex p-6">
          <FontAwesomeIcon
            icon={faCommentsAlt as IconProp}
            className="text-2xl text-gray-500"
          />
          <h2
            id="timeline-title"
            className="text-xl text-gray-900 ml-3 font-medium"
          >
            Upcoming conversations
          </h2>
        </div>
        {props.isLoading ? (
          <Loading text="Loading your conversations.." />
        ) : props.agreementStatus === "Pending" ? (
          <>
            <p className="px-6 text-gray-500">
              No upcoming conversations yet until your agreement has been accepted
            </p>
          </>
        ) : (
          <ul className="divide-y divide-gray-200">
            {props.isManager ? !props.goalConv ? <div className="px-5">No conversations</div> : props.goalConv?.filter(status => status.status_active !== 0).map((obj, i) => {
              return (
                <div className="m-5 p-5 bg-slate-100 rounded-sm" key={i}>
                  <Disclosure>
                  {({ open }) => (
                    <>
                    <Disclosure.Button className="w-full text-left flex justify-between items-center">
                      {obj.firstname}
                      <ChevronRightIcon className={open ? 'w-5 rotate-90 transform' : 'w-5'} />
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      {obj?.goal_conversations.length <= 0 && <div className="text-xs mt-2">Goal Setting - Date not proposed or agreement is still pending <br /><strong>{obj.firstname}</strong> to accept your Growth Agreement to start setting goals</div>}
                      {obj?.goal_conversations.filter(conv => conv.status === 0).slice(0, 3).map((item, i) => {
                        if (item.type === "g") {
                          return (
                            <div className="col-span-12 lg:col-span-3" key={i}>
                              <div className={`py-3 h-full`}>
                                <h4
                                  className={`text-sm mb-2 flex justify-between items-center font-medium`}
                                >
                                  Goal Setting
                                  <p className="text-xs text-center text-gray-600">
                                    {new Date(item.date).toLocaleDateString(
                                      "en-AU"
                                    )}
                                  </p>
                                </h4>
                                <div className="flex justify-between">
                                  <div>
                                    <Link
                                      to="/conversation-goal-setting-manager"
                                      className="mt-2 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      state={{
                                        data: item.date,
                                        status: item.status_input,
                                        id: item.agreement_id,
                                        name: obj.firstname
                                      }}
                                    >
                                      View conversation
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (item.type === "c") {
                          return (
                            <div className="col-span-12 lg:col-span-3" key={i}>
                              <div className={`py-3 h-full`}>
                                <h4
                                  className={`text-sm mb-2 flex justify-between items-center font-medium`}
                                >
                                  Check-in
                                  <p className="text-xs text-center text-gray-600">
                                    {new Date(item.date).toLocaleDateString(
                                      "en-AU"
                                    )}
                                  </p>
                                </h4>
                                <div className="flex justify-between">
                                  <div>
                                    <Link
                                      to="/conversation-checkin-manager"
                                      className="mt-2 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      state={{
                                        data: item.date,
                                        status: item.status_input,
                                        id: item.agreement_id,
                                        name: obj.firstname
                                      }}
                                    >
                                      View conversation
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (item.type === "r") {
                          return (
                            <div className="col-span-12 lg:col-span-3" key={i}>
                              <div className={` py-3 h-full`}>
                                <h4
                                  className={`text-sm mb-2 flex justify-between items-center font-medium`}
                                >
                                  Reflection
                                  <p className="text-xs text-center text-gray-600">
                                    {new Date(item.date).toLocaleDateString(
                                      "en-AU"
                                    )}
                                  </p>
                                </h4>
                                <div className="flex justify-between">
                                  <div>
                                 
                                    <Link
                                      to="/conversation-reflection-manager"
                                      className="mt-2 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      state={{
                                        data: item.date,
                                        status: item.status_input,
                                        id: item.agreement_id,
                                        name: obj.firstname
                                      }}
                                    >
                                      View conversation
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </Disclosure.Panel></>
                      )}
                  </Disclosure>
                </div>
              );
            }) : 
            <div className="px-5">
            {props.goalConv?.filter(conv => conv.status === 0).slice(0, 3).map((item, i) => {
              if (item.type === "g") {
                return (
                  <div className="col-span-12 lg:col-span-3" key={i}>
                    <div className={`py-3 h-full`}>
                      <h4
                        className={`text-sm border-l-4 pl-3 mb-3 flex justify-between items-center font-medium`}
                      >
                        Goal Setting
                        <p className="text-xs text-center text-gray-600">
                          {new Date(item.date).toLocaleDateString(
                            "en-AU"
                          )}
                        </p>
                      </h4>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-sm">
                            This is about discussing your needs and
                            setting your development objectives and
                            targets as well as clarifying
                            expectations.
                          </p>
                          <Link
                            to="/conversation-goal-setting"
                            className="mt-5 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            state={{
                              data: item.date,
                              status: item.status_input,
                              id: item.agreement_id,
                            }}
                          >
                            Start conversation
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (item.type === "c") {
                return (
                  <div className="col-span-12 lg:col-span-3" key={i}>
                    <div className={`py-3 h-full`}>
                      <h4
                        className={`text-sm border-l-4 pl-3 mb-3 flex justify-between items-center font-medium`}
                      >
                        Check-in
                        <p className="text-xs text-center text-gray-600">
                          {new Date(item.date).toLocaleDateString(
                            "en-AU"
                          )}
                        </p>
                      </h4>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-sm">
                            This is about setting the frequent
                            development check-ins and tracking your
                            progress.
                          </p>
                          <Link
                            to="/conversation-checkin"
                            className="mt-5 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            state={{
                              data: item.date,
                              status: item.status_input,
                              id: item.agreement_id,
                            }}
                          >
                            Start conversation
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (item.type === "r") {
                return (
                  <div className="col-span-12 lg:col-span-3" key={i}>
                    <div className={` py-3 h-full`}>
                      <h4
                        className={`text-sm border-l-4 pl-3 mb-3 flex justify-between items-center font-medium`}
                      >
                        Reflection
                        <p className="text-xs text-center text-gray-600">
                          {new Date(item.date).toLocaleDateString(
                            "en-AU"
                          )}
                        </p>
                      </h4>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-sm">
                            This is about gathering feedback to
                            reflect on your progress and reviewing
                            your development goals.
                          </p>
                          <Link
                            to="/conversation-reflection"
                            className="mt-5 inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            state={{
                              data: item.date,
                              status: item.status_input,
                              id: item.agreement_id,
                            }}
                          >
                            Start conversation
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}</div>}
          
          </ul>
        )}
        {props.agreementStatus !== "Pending" && (
          <div className="text-right p-6 pt-0">
            <Link
              to={"/conversations"}
              className="pt-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
            >
              View all
              <ArrowNarrowRightIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default UpcomingConversationWidget;
