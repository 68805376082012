import Calendar from "../components/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faCalendar,
  faCalendarPlus,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import {
  faBellPlus,
  faBells,
  faCalendarCirclePlus,
  faEnvelope,
  faMessages,
} from "@fortawesome/pro-duotone-svg-icons";
import Loading from "../components/loading";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface IProps {
  title?: string;
}

export interface ICalendarEvent {
  event_date: any;
  event_title: any;
  event_theme: any;
}

export const Activity = (props: IProps) => {
  const axios = require("axios");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [goalConv, setGoalConv] = useState([]);
  const [userProps, setUserProps] = useState();
  const [eventCalendar, setEventCalendar] = useState([]);
  const [next, setNext] = useState(8);
  const [manager, setManager] = useState();

  const [convFreq, setConvFreq] = useState({
    goal_check_in_time: null,
    goal_reflection_time: null,
    goal_review_time: null,
  });
  const [convPlanDetails, setConvPlanDetails] = useState({
    description: "",
    goal_check_in_time: "",
    goal_reflection_time: "",
    goal_review_time: "",
    id: null,
    image_url: "",
    list_features: "",
    name: "",
  });

  const handleMoreConv = () => {
    setNext(next + 4);
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        data.manager[0] && setManager(data.manager[0].firstname);
        getOrgDetails(data.organisation.id);
        if (data.agreements.length >= 1) {
          getConversationList(data.agreements[0].id);
          setUserProps(data.users);
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getOrgDetails = (orgId) => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/organisations/detail/${orgId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.organisation;

        setConvFreq({
          ...convFreq,
          goal_check_in_time: data.agreement_plan.goal_check_in_time,
          goal_reflection_time: data.agreement_plan.goal_reflection_time,
          goal_review_time: data.agreement_plan.goal_review_time,
        });
        setConvPlanDetails(data.agreement_plan);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setIsLoading(false);
      });
  };

  const processCalendarInvite = (date, title, manager, type) => {
    const calendarInviteObj = {
      title: checkConversationTypeFunc(title),
      description:
        "A " +
        checkConversationTypeFunc(title) +
        " conversation with " +
        manager,
      start: date,
    };

    switch (type) {
      case "google":
        window.open(google(calendarInviteObj), "_blank");
        break;
      case "outlook":
        window.open(outlook(calendarInviteObj), "_blank");
        break;
      case "office365":
        window.open(office365(calendarInviteObj), "_blank");
        break;
      case "yahoo":
        window.open(yahoo(calendarInviteObj), "_blank");
        break;
      case "ics":
        window.open(ics(calendarInviteObj), "_blank");
        break;
      default:
        return "border-purple-200 text-purple-800 bg-purple-100";
    }
  };

  const getConversationList = (id) => {
    let eventData = [];
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversations/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data;
        setGoalConv(data.agreement.goal_conversations);
        data.agreement.goal_conversations.forEach((item) => {
          eventData.push({
            event_date: item.date,
            event_title: checkConversationTypeFunc(item.type),
            event_theme: "blue",
          });
        });
        setEventCalendar(eventData);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const checkConversationTypeFunc = (type) => {
    if (type === "c") return "Check In";
    if (type === "g") return "Goal Setting";
    if (type === "r") return "Reflection";
  };

  useEffect(() => {
    getUserProfile();
    console.log(userProps);
  }, []);

  return (
    <div className="mt-12">
      <h2 className="text-4xl font-medium mb-8 pb-4 text-slate-900">
        Calendar
      </h2>
      <h3 className="text-xl leading-6 font-medium text-gray-600 mb-5">
        Your cadence overview
      </h3>
      <div className="shadow rounded-md bg-white p-5 h-full mb-12">
        <div className="grid grid-cols-12 gap-6 ">
          <div className="col-span-12 lg:col-span-4">
            <div className="border-l-4 pl-3 mb-3">
              <p className="text-gray-400">Goals Setting</p>
              <p className="text-2xl font-medium">Once</p>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="border-l-4 pl-3 mb-3">
              <p className="text-gray-400">Goals Check-in</p>
              <p className="text-2xl font-medium">
                {convFreq.goal_check_in_time &&
                  (convFreq?.goal_check_in_time).replace("|", " ")}
              </p>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="border-l-4 pl-3 mb-3">
              <p className="text-gray-400">Goals Reflection</p>
              <p className="text-2xl font-medium">
                {convFreq.goal_reflection_time &&
                  (convFreq?.goal_reflection_time).replace("|", " ")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-12 mb-12">
        {!manager ? <div className="col-span-12">Sorry, we are working hard to bring this feature to our coaches! stay tuned.</div> :<>
        <div className="col-span-4">
          <div className="h-full rounded-lg bg-white overflow-hidden shadow">
            {goalConv.length === 0 && (
              <Loading className="mt-5" text="Loading your conversations.." />
            )}
            {goalConv.slice(0, next).map((item, i) => {
              if (item.type === "g") {
                return (
                  <div
                    key={i}
                    className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150 border-b"
                  >
                    <div
                      className={`${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      } p-5 h-full`}
                    >
                      <div className="flex justify-between">
                        <h4 className="text-lg mb-1 font-medium">
                          Goal Setting
                        </h4>
                        {item.status_input === 1 && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>

                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>
                      <div className="flex mt-2">
                        <Link
                          to="/conversation-goal-setting"
                          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                          state={{
                            data: item.date,
                            status: item.status_input,
                            id: item.agreement_id,
                          }}
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faMessages as IconProp}
                          />
                          {item.status_input === 1 ? `View ` : `Start `}
                          conversation
                        </Link>
                        {item.status_input !== 1 ? (
                          <>
                            <Link
                              to=""
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faCalendarCirclePlus as IconProp}
                              />
                              Invitation
                            </Link>
                            <Link
                              to=""
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faBells as IconProp}
                              />
                              Reminder
                            </Link>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
              if (item.type === "c") {
                return (
                  <div
                    key={i}
                    className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150 border-b"
                  >
                    <div
                      className={`px-5 py-3 h-full ${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      }`}
                    >
                      <div className="flex justify-between">
                        <h4
                          className={`text-lg mb-1 font-medium ${
                            item.status_input === 1 &&
                            `border-green-500 text-green-800`
                          }`}
                        >
                          Check-in
                        </h4>
                        {item.status_input === 1 && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>

                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>

                      <div className="flex mt-2">
                        <Link
                          to="/conversation-checkin"
                          className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          state={{
                            data: item.date,
                            status: item.status_input,
                            id: item.agreement_id,
                          }}
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faMessages as IconProp}
                          />
                          {item.status_input === "1" ? `View ` : `Start `}
                          Conversation
                        </Link>
                        {item.status_input !== 1 ? (
                          <>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                  Invitation
                                  <ChevronDownIcon
                                    className="ml-2 -mr-1 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                                  <Menu.Item>
                           
                                      <button
                                        className="py-2 text-sm hover:font-medium"
                                        onClick={(e) =>
                                          processCalendarInvite(
                                            item.date,
                                            item.type,
                                            manager,
                                            "outlook"
                                          )
                                        }
                                      >
                                        Outlook
                                      </button>
                       
                                  </Menu.Item>
                                  <Menu.Item>
                                 
                                      <button
                                        className="py-2 text-sm hover:font-medium"
                                        onClick={(e) =>
                                          processCalendarInvite(
                                            item.date,
                                            item.type,
                                            manager,
                                            "ics"
                                          )
                                        }
                                      >
                                        Apple Calendar
                                      </button>
                         
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                            {/* <Link
                              to=""
                              onClick={(e) => processCalendarInvite(item.date, item.type, manager, "outlook")}
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faCalendarCirclePlus as IconProp}
                              />
                              Invitation
                            </Link> */}
                            <Link
                              to=""
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faBells as IconProp}
                              />
                              Reminder
                            </Link>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
              if (item.type === "r") {
                return (
                  <div
                    key={i}
                    className="col-span-12 md:col-span-6 lg:col-span-4 transition ease-in-out delay-150 border-b"
                  >
                    <div
                      className={`p-5 h-full ${
                        item.status_input === 1 ? `bg-green-100` : `bg-white`
                      }`}
                    >
                      <div className="flex justify-between">
                        <h4
                          className={`text-lg mb-1 font-medium ${
                            item.status_input === "1" &&
                            `border-green-500 text-green-800`
                          }`}
                        >
                          Reflection
                        </h4>
                        {item.status_input === "1" && (
                          <FontAwesomeIcon
                            className="ml-2 text-xl text-green-600"
                            icon={faCheck as IconProp}
                          />
                        )}
                      </div>

                      <p className="text-sm text-gray-600 mb-3">{item.date}</p>
                      <div className="flex mt-2">
                        <Link
                          to="/conversation-reflection"
                          className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          state={{
                            data: item.date,
                            status: item.status_input,
                            id: item.agreement_id,
                          }}
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faMessages as IconProp}
                          />
                          {item.status_input === "1" ? `View ` : `Start `}
                          conversation
                        </Link>
                        {item.status_input !== 1 ? (
                          <>
                            <Link
                              to=""
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faCalendarCirclePlus as IconProp}
                              />
                              Invitation
                            </Link>
                            <Link
                              to=""
                              className="mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faBells as IconProp}
                              />
                              Reminder
                            </Link>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="col-span-8">
          <Calendar events={eventCalendar} />
        </div></>
      }
      </div>
    </div>
  );
};
