import Tips from "../components/tips";
import Goals from "../widgets/goals";
import UpcomingConversationWidget from "../widgets/upcomingConversation";

import { faShieldCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import Users from "../components/users";
import { IAgreement, IUserProfile } from "../interface/generic";
import { Alert } from "@mui/material";
import { faCheckCircle, faClock } from "@fortawesome/pro-duotone-svg-icons";
import { CheckCircleIcon } from "@heroicons/react/solid";

export interface IProps {
  title?: string;
}

export const Dashboard = (props: IProps) => {
  const axios = require("axios");
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState<IUserProfile>();
  const [listAgreements, setListAgreements] = useState<IAgreement[]>();
  const [goalUsers, setGoalUsers] = useState([]);
  const [goalDetails, setGoalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [goalConv, setGoalConv] = useState([]);
  const [userProps, setUserProps] = useState();
  const [newAgreement, setNewAgreement] = useState(false);
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let current_datetime = new Date();
  let formatted_date =
    current_datetime.getDate() +
    " " +
    months[current_datetime.getMonth()] +
    " " +
    current_datetime.getFullYear();
  let goalsError;

  const getGoals = (agreementId) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/goals/list/${agreementId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data[0];
        setGoalDetails(Object.values(data.goals));
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        goalsError = Object.values(error.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getListAgreements = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/agreement/list`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.agreement;
        if(data.length) {
          // setListAgreements(data);
          setIsLoading(false);
          if(data[0].goal_conversations?.length === 1) {setNewAgreement(true)}
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setListAgreements(data.users);
        setGoalUsers(data.users);
        setUserData(data);
        if (data.agreements.length >= 1) {
          getGoals(data.agreements[0].id);
          getConversationList(data.agreements[0].id);
          setUserProps(data.users);
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getConversationList = (id) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversations/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data;
        setGoalConv(data.agreement.goal_conversations);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  useEffect(() => {
    getUserProfile();
    getListAgreements();
  }, []);

  const statusNotAvailable = listAgreements?.filter(
    (item) => item.status === 0
  );
  const statusAvailable = listAgreements?.filter((item) => item.status === 1);


  return (
    <>
      {isLoading && userData ? (
        <Loading text="Loading your dashboard..." />
      ) : (
        <>
        {(userData?.user_type === "endUser" && userData.agreements[0].status_label === "Agreed") &&
          <div className="rounded-md bg-blue-50 p-4 mt-12">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-blue-800">
                  Congratulation {userData?.firstname}, your agreement has been
                  accepted!
                </p>
              </div>
            </div>
          </div>
        }
          <main className="mt-12 pb-10">
            {/* Page header */}
            <div className="mx-auto flex justify-between flex-col md:flex-row ">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-4xl font-medium pb-4 text-slate-900">
                    Welcome, {userData?.firstname}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mr-2">
                  <svg
                    className="-ml-1 mr-1.5 h-2 w-2 text-blue-400"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  {userData?.user_type === "endUser" ? "Member" : "Manager"}
                </span>
                {userData?.agreements.length >= 1 &&
                  userData?.user_type === "manager" && userData?.agreements[0].status_label !== "Reset" && (
                    <>
                      <span
                        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium mr-2 ${
                          userData?.agreements &&
                          userData?.agreements[0]?.status_label === "Agreed"
                            ? "bg-gray-100 text-gray-800"
                            : "bg-orange-100 text-orange-800"
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faClock as IconProp}
                          className="mr-1"
                        />
                        {statusNotAvailable?.length >= 1
                          ? statusNotAvailable?.length
                          : "No"} Pending agreement
                      </span>
                    </>
                  )}
                {userData?.agreements.length >= 1 &&
                  userData?.user_type === "endUser" && userData?.agreements[0].status_label !== "Reset" && (
                    <>
                      <span
                        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium  ${
                          userData?.agreements &&
                          userData?.agreements[0]?.status_label === "Agreed"
                            ? "bg-green-100 text-green-800"
                            : "bg-orange-100 text-orange-800"
                        }`}
                      >
                        {userData?.agreements &&
                        userData?.agreements[0]?.status_label === "Pending" ? (
                          <FontAwesomeIcon
                            icon={faClock as IconProp}
                            className="text-yellow-500 mr-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheckCircle as IconProp}
                            className="text-green-500 mr-1"
                          />
                        )}
                        Agreement {userData?.agreements[0]?.status_label}
                      </span>
                    </>
                  )}
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-600 mr-2">
                  {formatted_date}
                </span>
              </div>
            </div>
            <Alert>Thank you for participating on our Beta testing, we would love to hear your feedback, complaints, advices and any contribution that would help us to shape this application better. If you have any information that you would like to share with us directly, please drop us an email at <a href="mailto: contact@qonvr.com">contact@qonvr.com</a></Alert>

            <div className="mt-8 grid grid-cols-12 gap-6">
              <section className="col-span-12 lg:col-span-3">
                <Tips />
              </section>
              <section className="col-span-12 lg:col-span-4">
                {userData?.user_type === "manager" ? (
                  <Users
                    details={listAgreements}
                    isLoading={isLoading}
                    agreementStatus={userData?.agreements[0]?.status_label}
                  />
                ) : (
                  <Goals
                    goals={goalDetails}
                    isManager={userData?.user_type}
                    isLoading={isLoading}
                    agreementStatus={userData?.agreements[0]?.status_label}
                  />
                )}
              </section>
              <section className="col-span-12 lg:col-span-5">
                <UpcomingConversationWidget
                  goalConv={
                    userData?.user_type === "manager" ? userProps : goalConv
                  }
                  isLoading={isLoading}
                  agreementStatus={userData?.agreements[0]?.status_label}
                  isManager={userData?.user_type === "manager" ? true : false}
                />
              </section>
            </div>
          </main>
        </>
      )}
    </>
  );
};
