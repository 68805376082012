import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import {
  IBasicUserProfile,
  IOrganisation,
  IUserProfile,
} from "../interface/generic";
import {
  InformationCircleIcon,
  MailIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { PlusSmIcon as PlusSmIconOutline } from "@heroicons/react/outline";
import API from "./../api/api";
import Loading from "./loading";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export interface IProps {
  title?: string;
}

export const Manage = (props: IProps) => {
  const token = localStorage.getItem("token");

  const { userProfile } = useContext(ProfileContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({
    title: "",
    type: "",
    message: null,
  });
  const [userSignupData, setUserSignupData] = useState<IBasicUserProfile>({
    firstname: "",
    lastname: "none",
    email_address: "",
  });
  const [organisation, setOrganisation] = useState<IOrganisation>();

  const addUserFunction = async () => {
    setIsLoading(true);
    try {
      const response = await API.post(`/invite/user`, userSignupData, {
        headers: {
          'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
        },
      });
      if (response) {
        setIsError({ ...isError, type: "" });
        getUserList();
        setIsLoading(false);
      }
    } catch (error) {
      // Handle Error Here
      setIsLoading(false);
      console.log(error.response)
      setIsError({
        ...isError,
        type: "userAdd",
        title: error.response.status,
        message: error.response.data.message
        ? (result) => [...result, error.response.data.message]
        : Object.values(error.response.data.errors)
      });
    }
  };

  const getUserList = async () => {
    setIsLoading(true);
    try {
      const response = await API.get(
        `organisations/detail/${userProfile.organisation.id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
          },
        }
      );
      if (response) {
        setIsLoading(false);
        setOrganisation(response.data.organisation);
      }
    } catch (error) {
      // Handle Error Here
      setIsLoading(false);
      setIsError({
        ...isError,
        type: "userList",
        message:  error.response.data.message
        ? (result) => [...result, error.response.data.message]
        : Object.values(error.response.data.errors)
      });
    }
  };

  const removeUser = async (id) => {
    try {
      const response = await API.delete(`users/delete/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
        },
      });
      if (response) {
        setIsLoading(false);
        getUserList();
        setOrganisation(response.data.organisation);
      }
    } catch (error) {
      // Handle Error Here
      setIsLoading(false);
      setIsError({
        ...isError,
        title: error.message,
        type: "userList",
        message:  error.response.data.message
        ? (result) => [...result, error.response.data.message]
        : Object.values(error.response.data.errors),
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserSignupData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getUserList();
  }, []);


  return (
    <>
      <div className="mt-12 mb-3">
        <p className="text-md font-medium">Let's invite your team member</p>
      </div>
      <div className="grid gap-5 lg:grid-cols-2 lg:max-w-none">
        <div>

   
            <div className=" relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <UserIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="firstname"
                id="firstname"
                onChange={handleChange}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full p-3 pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Full name"
              />
            </div>

        </div>
    

    
  
      <div className="flex rounded-md shadow-sm w-full">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email_address"
            id="email_address"
            onChange={handleChange}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-l-md"
            placeholder="you@example.com"
          />
        </div>
        <button
        type="button"
        onClick={addUserFunction}
        className="flex items-center rounded-r-md border border-transparent bg-indigo-600 px-4 text-sm text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
           <PlusSmIconOutline className="h-5 w-5" aria-hidden="true" /> 
          Add user
        </button>
      </div>

       
          </div>
  

      {isError.type === "userAdd" && (
          <Alert severity="error" variant="filled" className="mt-2">
          <ul className="space-y-1">
                    {isError.message.map((item, i) => {
                      return <li key={i}>{item}</li>;
                    })}
                  </ul>
        </Alert>
      )}
      <div className="rounded-md bg-blue-50 p-4 mt-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Your team member will receive an email invitation where they can
              signup and accept your invitation.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-9 mb-3">
        <p className="text-md font-medium">Your team</p>
      </div>
      <div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow  border border-gray-200 sm:rounded-md">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={6}>
                          <div className="p-3">
                            <Loading />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      organisation?.users?.length <= 0 ? <tr>
                      <td colSpan={6}>
                        <div className="p-3">
                          <p className="text-sm text-gray-500">No users available</p>
                        </div>
                      </td>
                    </tr> :
                      organisation?.users?.map((person) => (
                        <tr key={person.email_address} className={"bg-white"}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {person.firstname}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {person.lastname}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {person.email_address}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {person.user_type}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {person.status_active === 0 ? <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
          <circle cx={4} cy={4} r={3} />
        </svg>
        Not activated
      </span> :  <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
          <circle cx={4} cy={4} r={3} />
        </svg>
        Active
      </span>}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              className="text-blue-600 hover:text-blue-900"
                              onClick={(e) => removeUser(person.id)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isError.type === "userList" && (
         <Alert severity="error" className="mt-2">
         <ul className="space-y-1">
                    {isError.message.map((item, i) => {
                      return <li key={i}>{item}</li>;
                    })}
                  </ul>
       </Alert>
      )}
    </>
  );
};
