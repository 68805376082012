import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseyeArrow } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IGoal } from "../interface/generic";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { useState } from "react";
import Loading from "../components/loading";

export interface IProps {
  title?: string;
  description?: string;
  isManager?: string;
  goals?: any[];
  error?: [];
  isLoading?: boolean;
  agreementStatus?: string;
}

const GoalsWidget = (props: IProps) => {

  //console.log(props.goals)
  return (
    <>
    <div className="shadow sm:rounded-md mb-6 bg-white h-full">
      <div className="flex p-6">
        <FontAwesomeIcon
          icon={faBullseyeArrow as IconProp}
          className="text-2xl text-gray-500"
        />
        <h2
          id="timeline-title"
          className="text-xl text-gray-900 ml-3 font-medium"
        >
          Goals
        </h2>
      </div>{props.agreementStatus === "Pending" || props.goals.length <= 0 ? <><p className="px-6 text-gray-500">No goals set so far.</p></> :
      props.isLoading ? <Loading text="Loading your goals.."/> :
      <ul className="divide-y divide-gray-200">
        {props.goals.map((goal) => (
          <li key={goal.id}>
            <div className="px-6 py-4">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-blue-600 truncate">
                  {goal.name}
                </p>
                <div className="ml-2 flex-shrink-0 flex">
                  <p
                    className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full 
                    ${goal.status_label === "Paused" && "bg-gray-300 text-gray-800"}
                    ${goal.status_label === "In Progress" && "bg-green-100 text-green-800"}
                    ${goal.status_label === "Achieved" && "bg-pink-100 text-pink-800"}
                    ${goal.status_label === "Reset" && "bg-orange-100 text-orange-800"}
                  `}
                  >
                    {goal.status_label}
                  </p>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    {goal.description}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}

        
      </ul>
      }
      {(props.agreementStatus !== "Pending" || props.goals.length >= 1) &&
      <div className="text-right p-6 pt-0">
        <a
          href="/goals"
          className="pt-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          View all
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </a>
      </div>
      }
    
    </div>
    </>
  );
};

export default GoalsWidget;
