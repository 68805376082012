import { CheckIcon, XCircleIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  IAgreement,
  IConversationFrequency,
  IOnboardingDeals,
  IUser,
} from "../interface/generic";
import { Alert, Snackbar } from "@mui/material";

import Loading from "./loading";
import { faUserCheck, faSpinnerThird, faUserCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Dialog } from "@headlessui/react";
import { faHandshakeAngle } from "@fortawesome/pro-light-svg-icons";
const axios = require("axios");

export interface IProps {
  title?: string;
  conversationFrequency?: IConversationFrequency;
}

const Agreement = (props: IProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  // user profile context
  const { conversationFrequency } = props;
  const [dateError, setDateError] = useState(false);
  const [agreementPending, setAgreementPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dealExists, setDealExists] = useState(false);
  const [userProfile, setUserProfile] = useState<IUser>();
  const [dealLoading, setDealLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState<IAgreement>();
  const [agreementDetails, setAgreementDetails] = useState<IOnboardingDeals>({
    agreement_plan_id: null,
    date: null,
    to_user: null,
    status_onboarding: null,
  });
  const [isError, setIsError] = useState({
    title: "",
    description: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const getCurrentDeal = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/agreement/list`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.agreement[0];
        if (data) {
          console.log(data)
          data.status !== 3 && setDealExists(true);
          setIsLoading(false);
          setAgreementPending(data.status_label === "Pending" ? true : false);
          setCurrentAgreement(data);
          //console.log(currentAgreement, agreementPending)
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data;
        setUserProfile(data.profile);
        setAgreementDetails({
          ...agreementDetails,
          to_user: data.profile.manager[0].id,
        });
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const makeDealFunction = () => {
    setDealLoading(true)
    if (agreementDetails.date == null) {
      setDateError(true);
      setAgreementPending(false);
      setDealLoading(false)
    } else {
      setDateError(false);
      axios({
        method: "post",
        url: `https://api.qonvr.co/api/agreement/create`,
        data: agreementDetails,
        crossDomain: true,
        headers: {
          'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
        },
      })
        .then(function (response) {
          // //console.log(response);
          setAgreementPending(true);
          setDealLoading(false)
          setIsOpen(true)
        })
        .catch(function (error) {
          // handle error
          console.error(error);
          setAgreementPending(false);
          setDealLoading(false)
          setShowToast(true);
          setIsError({
            ...isError,
            title: error.message,
            description: error.response.data.message,
          });
        });
    }
  };

  useEffect(() => {
    getUserProfile();
    getCurrentDeal();
    // //console.log(userProfile)
    // //console.log(agreementPending)
  }, []);

  useEffect(() => {
    // //console.log(agreementDetails)
  }, [agreementDetails]);


  return (
    <>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="w-full fixed "
      >
        <div className="fixed w-full left-0 ">
          <Alert variant="filled" severity="error">
            {isError.description}
          </Alert>
        </div>
      </Snackbar>
      <div className="grid grid-cols-12 gap-6 mt-12">
        {isLoading ? (
          <div className="col-span-12">
            <Loading text="Checking your deal..." />
          </div>
        ) : (
          <>
            <div className="col-span-12 lg:col-span-3">
              <div
                key={conversationFrequency?.title}
                className="relative h-full z-10 shadow rounded-lg bg-white overflow-hidden "
              >
                <div className="p-6 pb-4">
                  <h2 className="text-xl leading-6 font-medium text-black text-center mb-4">
                    Your Cadence
                  </h2>
                  <p className="text-center text-lg text-gray-700 text-center my-5">
                    {conversationFrequency?.title}
                  </p>
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={conversationFrequency?.iconName}
                      className="text-6xl text-gray-300"
                    />
                  </div>
                  <p className="mt-5 text-sm text-gray-700 h-16 text-center">
                    {conversationFrequency?.description}
                  </p>
                </div>
                <div className="pt-5 px-6 pb-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase text-center">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {conversationFrequency?.inclusions?.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-3">
              <div className="h-full rounded-lg bg-white overflow-hidden  shadow">
                <div className="p-6">
                  <h3 className="text-xl leading-6 font-medium text-black text-center mb-4">
                    Goal Setting Conversation
                  </h3>
                  <p className="mt-4 mb-2 text-sm text-gray-800 text-center">
                    Propose a date with {userProfile?.manager[0].firstname}
                  </p>
                  <p  className="text-xs text-gray-700 text-center">(check availability before proposing)</p>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md my-4 disabled:bg-gray-100 disabled:text-gray-400 disabled:shadow-none"
                    aria-describedby="date"
                    disabled={dealExists}
                    value={dealExists ? currentAgreement?.date : null}
                    onChange={(e) => {
                      e.target.value && setDateError(false);
                      if (
                        userProfile.organisation.plan_selected === "endUser"
                      ) {
                        setAgreementDetails({
                          ...agreementDetails,
                          agreement_plan_id: conversationFrequency.id,
                          date: e.target.value,
                          status_onboarding: true
                        });
                      } else {
                        setAgreementDetails({
                          ...agreementDetails,
                          date: e.target.value,
                          status_onboarding: true
                        });
                      }
                    }}
                  />
                  {dateError && (
                    <div className="rounded-md bg-red-50 p-3">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon
                            className="h-4 w-4 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-xs m-0 p-0 text-red-600">
                            Date cannot be empty
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <p className="mt-4 text-xs text-gray-400 text-center">
                    Once accepted. meeting invites to this conversation will be
                    automatically sent to your emails
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="h-full rounded-lg bg-white overflow-hidden  shadow">
                <div className="px-4 py-5 sm:px-6">
                  <h2 className="text-xl leading-6 font-medium text-black text-center mb-4">
                    Agreement
                  </h2>
                  <p className="mt-4 text-sm text-gray-700 text-center">
                    Your formalised agreement with your manager, lead or coach
                    for when and how to have development conversations
                  </p>
                </div>
                <div className=" px-4 pt-5 pb-2 sm:px-6 pb-5 border-y border-gray-200">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
                    <div className="sm:col-span-2">
                      <dd className="py-3 px-5">
                        <div className="flex flex-col justify-center">
                        <FontAwesomeIcon
                            icon={faUserCircle as IconProp}
                            className={`text-6xl text-gray-400`}
                          />
                          <div className="text-center mt-2">
                            <h3 className="text-xl text-black text-md mb-0 font-medium">
                              {userProfile?.firstname}
                            </h3>
                          
                          </div>
                        </div>
                      </dd>
                    </div>
                    <div className="sm:col-span-1 text-center pt-10 text-gray-500">
                      <FontAwesomeIcon
                        icon={faHandshakeAngle as IconProp}
                        className={`text-6xl ${agreementPending && "text-orange-400"} text-gray-400`}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <dd className="py-3 px-5">
                        <div className="flex flex-col justify-center">
                        <FontAwesomeIcon
                            icon={faUserCircle as IconProp}
                            className={`text-6xl text-gray-400`}
                          />
                          <div className="text-center mt-2">
                            <h3 className="text-xl text-black text-md mb-0 font-medium">
                              {userProfile?.manager[0].firstname || "Manager"}
                            </h3>
                          </div>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="text-center pt-8 pb-5">
                  {userProfile?.agreements?.length > 0 &&
                  userProfile?.agreements[0].status_label ===
                    "Agreed" ? null : (
                    <button
                      type="button"
                      className={`inline-flex items-center px-5 py-4 border border-transparent shadow text-md disabled:bg-gray-400 leading-4 font-medium rounded-md text-white transition-all	 ${
                        agreementPending ? "bg-orange-500" : "bg-slate-700"
                      }`}
                      onClick={(e) => makeDealFunction()}
                      disabled={agreementPending || dealLoading}
                    >
                      {dealLoading && 
                     <FontAwesomeIcon
                        icon={faSpinnerThird as IconProp}
                        className={`text-white animate-spin mr-2`}
                      />}
                      {agreementPending ? "Pending" : "Submit"}
                    </button>
                  )}
                </div>
                {isLoading ? <Loading /> : null}

                {userProfile?.agreements?.length > 0 &&
                userProfile?.agreements[0].status_label === "Agreed" && (
          
                  <div className="text-center rounded-full bg-green-200 mx-auto w-80 py-2">
                   <FontAwesomeIcon
                        icon={faUserCheck as IconProp}
                        className={`text-green-900 mr-2`}
                      />
                      {userProfile?.manager[0].firstname} has agreed on your deal.
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-xl rounded bg-white p-5 text-center">
          <Dialog.Title className='text-2xl font-medium mb-3'>Success!</Dialog.Title>
          <Dialog.Description className='mb-8'>
          <p>You have submitted your Agreement with <strong>{userProfile?.manager[0].firstname}</strong></p>
          </Dialog.Description>
            <a href="/dashboard" className="mb-5 inline-flex justify-center items-center rounded-md border border-transparent bg-green-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Let's start!</a>
          </Dialog.Panel>
        </div>
    </Dialog>
    </>
  );
};

export default Agreement;
