import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import {
  faArrowRight,
  faArrowLeft,
  faLightbulbOn,
  faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Switch, Tab } from "@headlessui/react";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { IGoalSteps, IUserProfile } from "../interface/generic";
import CheckInGoal from "../widgets/checkin-goal";

export interface IProps {
  title?: string;
  isManager?: boolean;
}

type LocationProps = {
  state: {
    data: any;
    status: any;
    id: any;
    isManager: boolean;
  };
};

const steps = [
  {
    id: 1,
    description:
      "Gathering all the courage that I have to actually speak about this to the line manager",
  },
  {
    id: 2,
    description:
      "Plan any financial hit on this that would make me less capable",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CheckIn = (props: IProps) => {
  const { userProfile } = useContext(ProfileContext);
  const axios = require("axios");
  const token = localStorage.getItem("token");
  const [stage, setStage] = useState("start");
  const [conversation, setConversation] = useState("prepare");
  const [isOpen, setIsOpen] = useState(false);
  const [completeGoal, setCompleteGoal] = useState(false);
  const [goalDetails, setGoalDetails] = useState([]);
  const [goalSteps, setGoalSteps] = useState([]);
  const [goalId, setGoalId] = useState();
  const [currentStep, setCurrentStep] = useState<IGoalSteps>();
  const [userData, setUserData] = useState<IUserProfile>();
  const [currentAction, setCurrentAction] = useState<IGoalSteps>();

  const [checkInDetails, setCheckInDetails] = useState({
    goal_id: null,
    date: null,
    agreement_id: null,
    complete_goal: null,
    improved_or_developed_notes: null,
    steps: null,
    previousQuestion: null,
  });

  // get details from Link on conversation page
  const location = useLocation() as unknown as LocationProps;
  const dateFromConv = location.state?.data;
  const convStatus = location.state?.status;
  const agreementId = location.state?.id;
  const isManager = location.state?.isManager || props.isManager

  const removeStep = (step) => {
    setGoalSteps(goalSteps.filter((item) => item !== step));
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        props.isManager || isManager ? getGoals(agreementId) : getGoals(data.agreements[0].id)
        getConvoDetail(data.agreements[0].id)
        setUserData(data)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const getGoals = (agreementId) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/goals/list/${agreementId}`,
      // url: `http://localhost:3007/goals`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        //console.log(response)
        const data = response.data[0];
        const goalList:any = Object.values(data.goals)
        setGoalDetails(goalList.filter(item => item.agreement_id === agreementId));
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        // setIsLoading(false)
      });
  };

  const getConvoDetail = (agreementId) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversations/${agreementId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data;
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const processStep = (type) => {
    if (type === "step")
      if (currentStep) setGoalSteps((goalSteps) => [...goalSteps, currentStep]);
  };

  useEffect(() => {
    getUserProfile();
    return () => {};
  }, []);

  useEffect(() => {
    setCheckInDetails({ ...checkInDetails, steps: goalSteps });
  }, [goalSteps]);

  useEffect(() => {
    //console.log(checkInDetails);
  }, [checkInDetails]);

  useEffect(() => {
    setCheckInDetails({ ...checkInDetails, complete_goal: completeGoal });
  }, [completeGoal]);

  console.log(userData)


  return (
    <>
      {stage === "start" && (
        <div className="mt-16">
          <div className="mb-16">
            <h1 className="text-5xl font-medium text-gray-900">
              Hey <strong>{userProfile.firstname}</strong>
            </h1>
            <p className="mt-4 mb-10 text-2xl">
              We will be stepping through these
              <span className="font-medium">steps</span>
              <br />
              for a more constructive check-in conversation
            </p>
            <div className="grid grid-cols-12 gap-20 items-end">
              <div className="col-span-3">
                <div className="h-48 flex content-end">
                  <img src="/plan-goal-conv.png" alt="" />
                </div>

                <div className="mt-5">
                  <span className="text-4xl">1</span>
                  <h2 className="font-medium">Prepare</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Prepare beforehand. Spend some time to review previous
                    conversation
                  </p>
                </div>
              </div>
              <div className="col-span-3">
                <div className="h-48 flex content-end">
                  <img src="/discuss-goal-conv.png" alt="" />
                </div>
                <div className="mt-5">
                  <span className="text-4xl">2</span>
                  <h2 className="font-medium">Discuss</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Have a discussion about your progress
                  </p>
                </div>
              </div>
              <div className="col-span-3">
                <div className="h-48 flex content-end">
                  <img src="/set-goal-conv.png" alt="" />
                </div>
                <div className="mt-5">
                  <span className="text-4xl">3</span>
                  <h2 className="font-medium">Agree</h2>
                  <p className="text-gray-500 mt-5 text-sm">
                    Agree and set the next action/s
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-10"
            onClick={(e) => setStage("conversations")}
          >
            Let's start the conversation
            <FontAwesomeIcon className="ml-2" icon={faArrowRight as IconProp} />
          </button>
        </div>
      )}
      {stage === "conversations" && (
        <div className="mt-16">
          <Tab.Group>
            <h2 className="text-3xl font-medium border-b-2 pb-4 mb-8 flex justify-between items-center">
              Check-in conversation
              {isManager && <span className="text-sm bg-gray-300 text-gray-500 inline-flex px-3 py-2 rounded-md">Manager</span>}
            </h2>
            {goalDetails.length >= 1 && <h3 className="text-xl leading-6 font-medium text-gray-600 mb-5">
              You are viewing goal
            </h3>}

            <div className="grid grid-cols-12 gap-4">
              {goalDetails.length >= 1 ? <>
              <div className="lg:col-span-2 md:col-span-4 sm:col-span-5">
                <Tab.List className="shadow rounded-lg bg-white grid grid-cols-1 gap-4 -mb-px overflow-hidden">
                  {goalDetails.map((item) => {
                    return (
                      <Tab
                        className={({ selected }) =>
                          selected
                            ? "bg-blue-700 font-medium text-white py-5"
                            : "text-gray-600 py-5"
                        }
                      >
                        {item.name}
                      </Tab>
                    );
                  })}
                </Tab.List>
              </div>
              <div className="lg:col-span-10 md:col-span-8 sm:col-span-7">
                <Tab.Panels>
                  {goalDetails.map((item) => {
                    return (
                      <Tab.Panel>
                        <CheckInGoal goalDetails={item} convoDate={dateFromConv} isManager={isManager} convStatus={convStatus}/>
                      </Tab.Panel>
                    );
                  })}
                </Tab.Panels>
              </div></> : <div className="col-span-12">{props.isManager ? `${userData.firstname} has not had any goals yet. Let them know to add goals to have a Check in conversation`  : "You have no goals yet, please add a goal to have a Check in conversation"}</div>}
            </div>
          </Tab.Group>
        </div>
      )}
    </>
  );
};

export default CheckIn;
