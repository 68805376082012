import "./App.css";

import { useCallback, useEffect, useState } from "react";
import { Dashboard } from "./pages/dashboard";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Profile } from "./components/profile";
import { Manage } from "./components/manage";
import { IUser, IUserProfile } from "./interface/generic";
import Onboarding from "./pages/onboarding";
import Header from "./components/header";
import Login from "./components/login";
import AgreementPage from "./pages/agreement";
import ConversationsPage from "./pages/conversations";
import GoalsPage from "./pages/goals";
import OnboardingManagers from "./pages/onboarding_managers";

import ProfileProvider from "./context/profileProvider";
import Loading from "./components/loading";
import API from "./api/api";
import GoalSetting from "./conversations/goal-setting";
import { Alert } from "@mui/material";
import CheckIn from "./conversations/checkin";
import Reflection from "./conversations/reflection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faGauge,
  faHandshakeSimple,
  faBullseyeArrow,
  faMessages,
  faUser,
  faCalendarRange,
} from "@fortawesome/pro-duotone-svg-icons";

import { faToggleOn, faToggleOff } from "@fortawesome/pro-duotone-svg-icons";
import ManagerReflection from "./pages/manager-reflection";
import { Activity } from "./pages/activity";

function App() {
  const [validLogin, setValidLogin] = useState(false);
  const [innerLoading, setInnerLoading] = useState(true);
  const [token, setToken] = useState("");
  const [stringLogin, setStringLogin] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<IUserProfile>();
  const [pageLoading, setPageLoading] = useState(true);
  const [isActivation, setIsActivation] = useState(false);
  const [isManagerActivation, setIsManagerActivation] = useState(false);
  const [isRegistration, setIsRegistration] = useState(false);
  const [isResetPassword, setResetPassword] = useState(false);
  const [resetPasswordDetail, setResetPasswordDetail] = useState("");
  const [activationDetail, setActivationDetail] = useState<IUser>();
  const [invalidActivation, setInvalidActivation] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const navigate = useNavigate();
  const location = useLocation();
  const axios = require("axios");
  const currentPathName = location.pathname;

  const signoutFunction = () => {
    if (userProfile) {
      localStorage.clear();
      window.location.reload();
    }
  };

  const getResetPasswordDetails = async () => {
    const stringValue = window.location.pathname;
    const getStringValue = stringValue.substring(
      stringValue.lastIndexOf("/") + 1
    );
    if (window.location.toString().includes("reset-password")) {
      setResetPassword(true);
      setResetPasswordDetail(getStringValue);
    }
  };
  const getActivationDetails = async () => {
    const stringValue = window.location.pathname;
    const getStringValue = stringValue.substring(
      stringValue.lastIndexOf("/") + 1
    );
    setStringLogin(getStringValue);
    if (window.location.toString().includes("activation")) {
      setIsActivation(true);
      try {
        const response = await API.get(`verify/${getStringValue}`);
        if (response) {
          setActivationDetail(response.data[0].user);
          setInvalidActivation(null);
          if(response.data[0].user.user_type === "manager") setIsManagerActivation(true)
        }
      } catch (error) {
        // Handle Error Here
        console.error(error);
        setInvalidActivation(true);
      }
    }
  };

  const getRegistrationDetails = () => {
    if (window.location.toString().includes("registration")) {
      setIsRegistration(true);
    }
  }

  const setTimeOut = () => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const getOnboardingStatus = async (token) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/onboarding`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        setOnboardingStatus(response.data[0].status_onboarding);
        setInnerLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        getUserProfile();
        setInvalidActivation(true);
        signoutFunction();
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const checkSelectedNav = (pathValue, currentValue) => {
    if (pathValue === currentValue) {
      return true;
    }
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      current: checkSelectedNav(currentPathName, "/dashboard"),
    },
    {
      name: "Agreement",
      href: "/agreement",
      current: checkSelectedNav(currentPathName, "/agreement"),
    },
    {
      name: "Goals",
      href: "/goals",
      current: checkSelectedNav(currentPathName, "/goals"),
    },
    {
      name: "Conversations",
      href: "/conversations",
      current: checkSelectedNav(currentPathName, "/conversations"),
    },
    {
      name: "Calendar",
      href: "/activity",
      current: checkSelectedNav(currentPathName, "/activity"),
    },
  ];

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setUserData(data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUserSession = () => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      setValidLogin(true);
      getOnboardingStatus(userToken);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const setMobileSettings = () => {

    // screen check
    if(window.innerWidth <= 768) {
      setToggleSidebar(true)
    }
  }

  const iconSelector = (type) => {
    if (type === "Dashboard") {
      return (
        <div className="w-8 flex justify-center">
          <FontAwesomeIcon
            className="text-white text-lg text-blue-500"
            icon={faGauge as IconProp}
          />
        </div>
      );
    }
    if (type === "Agreement") {
      return (
        <div className="w-8 flex justify-center">
          <FontAwesomeIcon
            className="text-white text-lg text-pink-500"
            icon={faHandshakeSimple as IconProp}
          />
        </div>
      );
    }
    if (type === "Goals") {
      return (
        <div className="w-8 flex justify-center">
          <FontAwesomeIcon
            className="text-white text-lg text-purple-500"
            icon={faBullseyeArrow as IconProp}
          />
        </div>
      );
    }
    if (type === "Conversations") {
      return (
        <div className="w-8 flex justify-center">
          <FontAwesomeIcon
            className="text-white text-lg text-orange-500"
            icon={faMessages as IconProp}
          />
        </div>
      );
    }
    if (type === "Calendar") {
      return (
        <div className="w-8 flex justify-center">
          <FontAwesomeIcon
            className="text-white text-lg text-indigo-500"
            icon={faCalendarRange as IconProp}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeOut();
    getActivationDetails();
    getResetPasswordDetails();
    getRegistrationDetails();
  }, []);

  useEffect(() => {
    getUserSession();
    setMobileSettings();
    //clean up useEffect
    return () => {
      setValidLogin(false);
      setInvalidActivation(false);
    };
  }, [validLogin]);

  if (!validLogin) {
    return (
      <>
        {isLoading ? (
          <div className="p-3 flex w-max rounded lg mx-auto mt-20">
            <Loading layout="page" />
          </div>
        ) : (
          <Login
            invalidActivation={invalidActivation}
            managerActivation={isManagerActivation}
            loginString={stringLogin}
            isActivation={isActivation}
            isRegistration={isRegistration}
            isResetPassword={isResetPassword}
            resetPasswordDetail={resetPasswordDetail}
            userActivationDetail={activationDetail}
            setIsRegistration={setIsRegistration}
            setValidLogin={setValidLogin}
            setToken={setToken}
          />
        )}
      </>
    );
  }

  return (
    <ProfileProvider>
      <div className="App">
        <div className="min-h-full">
        
          {pageLoading ? (
            <Loading text="Loading..." />
          ) : (
            <>
              <Header
                toggleSidebar={toggleSidebar}
                agreementStatus={userData?.agreements[0].status_label}
                onboardingStatus={onboardingStatus}
              />
              {!onboardingStatus &&
                userProfile?.profile.user_type === "endUser" && <Onboarding />}
              {!onboardingStatus &&
                userProfile?.profile.user_type === "manager" && (
                  <OnboardingManagers
                    setOnboardingStatus={setOnboardingStatus}
                  />
                )}
              {onboardingStatus && (
                <main>
                  <div
                    className={`flex-1 flex flex-col min-h-0 bg-gray-800 fixed h-full left-0 top-0 transition-all ${
                      !toggleSidebar ? "w-56" : "w-16"
                    }`}
                  >
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                      <div className="flex items-center flex-shrink-0 px-4 justify-between">
                        <Link to="/dashboard">
                          {!toggleSidebar ? (
                            <img
                              className="h-8 w-22 transition-all"
                              src="/qonvr-logo-bw.png"
                              alt="Workflow"
                            />
                          ) : null}
                        </Link>
                        <button
                          onClick={(e) => setToggleSidebar(!toggleSidebar)}
                          className='hidden md:block'
                        >
                          {toggleSidebar ? (
                            <FontAwesomeIcon
                              className="text-white text-3xl"
                              icon={faToggleOn as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-white text-3xl"
                              icon={faToggleOff as IconProp}
                            />
                          )}
                        </button>
                      </div>
                      <nav
                        className="mt-5 flex-1 px-2 bg-gray-800 space-y-1"
                        aria-label="Sidebar"
                      >
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}
                          >
                            {iconSelector(item.name)}
                            {!toggleSidebar && (
                              <span className="flex-1">{item.name}</span>
                            )}
                          </Link>
                        ))}
                      </nav>
                    </div>
                    <div className="flex-shrink-0 flex bg-gray-900 p-2">
                      <div className="flex items-center">
                        <div className="bg-gray-600 w-8 h-8 flex items-center justify-center rounded-full relative overflow-hidden">
                        <FontAwesomeIcon
                              className="text-white text-md"
                              icon={faUser as IconProp}
                            />
                        </div>
                        {!toggleSidebar && (
                          <div className="ml-3">
                            <p className="text-sm font-medium text-white">
                              {userProfile?.profile?.firstname}
                            </p>

                            <Link
                              to="/profile"
                              className="text-xs font-medium text-gray-300 group-hover:text-gray-200"
                            >
                              View profile
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      !toggleSidebar ? "pl-56" : "pl-16"
                    }  container mx-auto`}
                  ><div className="px-5">
                    <Routes>
                      <Route path="profile" element={<Profile />} />
                      <Route path="manage-users" element={<Manage />} />
                      {/* Conversations */}
                      <Route
                        path="conversation-goal-setting"
                        element={<GoalSetting />}
                      />
                      <Route
                        path="conversation-checkin"
                        element={<CheckIn />}
                      />
                       <Route
                        path="conversation-goal-setting-manager"
                        element={<GoalSetting isManager={true} />}
                      />
                       <Route
                        path="conversation-checkin-manager"
                        element={<CheckIn isManager={true} />}
                      />
                      <Route
                        path="conversation-reflection-manager"
                        element={<ManagerReflection />}
                      />
                      <Route
                        path="conversation-reflection"
                        element={<Reflection />}
                      />
                      <Route path="/" element={<Dashboard />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="agreement" element={<AgreementPage />} />
                      <Route path="goals" element={<GoalsPage />} />
                      <Route path="conversations"  element={<ConversationsPage />} />
                      <Route path="activity" element={<Activity />} />
                    </Routes>
                  </div></div>
                </main>
              )}
            </>
          )}
        </div>
      </div>
    </ProfileProvider>
  );
}

export default App;
