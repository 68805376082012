import { useContext, useEffect, useState } from "react";
import Subheader from "../components/subheader";
import { CheckIcon, ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { ProfileContext } from "../context/profileProvider";
import { useNavigate } from "react-router-dom";
import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { faHandsHelping } from "@fortawesome/pro-light-svg-icons";
import { Popover, Dialog } from "@headlessui/react";
import { InformationCircleIcon, LightBulbIcon } from "@heroicons/react/solid";

import { Manage } from "../components/manage";
import { tiers } from "../control/general";
const axios = require("axios");

export interface IOnboardingManagers {
  setOnboardingStatus: Function;
}

const welcomeText = [
  {
    title: `Start adding team members`,
    href: "#",
    description:
      "Invite your team members by email. We will onboard them step by step.",
    imageUrl: "/make-deal.png",
  },
  {
    title: `Agree 'when' & 'how often'`,
    href: "#",
    description:
      "Discuss and decide together on a realistic development conversation cadence.",
    imageUrl: "/set-goal.png",
  },
  {
    title: "Kick off goal setting",
    href: "#",
    description:
      "Next, accept the meeting invite from team members for the goal setting conversations",
    imageUrl: "/track-development.png",
  },
];

export const OnboardingManagers = (props: IOnboardingManagers) => {
  const [stages, setStages] = useState("welcome");
  const [isOpen, setIsOpen] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState('')
  // user profile context
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const setManagerPlan = async (planType?, plan?) => {
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/organisations/update/${userProfile.organisation.id}`,
      crossDomain: true,
      data: {
        plan_selected: planType || "",
        agreement_plan_id: plan || "",
      },
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const setOnboardingStatus = () => {
    axios({
      method: "put",
      url: `https://api.qonvr.co/api/onboarding`,
      data: {
        status_onboarding: true,
      },
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        props.setOnboardingStatus(true);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  return (
    <>
      <div className="relative pt-16 pb-20  lg:pt-10 lg:pb-10">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto">
          {stages === "welcome" && (
            <>
              <Subheader
                title="Welcome to Qonvr"
                description="Invest in quality conversations to progress on development goals"
              />
              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {welcomeText.map((post) => (
                  <div key={post.title} className="flex flex-col ">
                    <div className="flex-shrink-0">
                      <img
                        className="h-48 w-full object-cover"
                        src={post.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <div className="block mt-2">
                          <p className="text-xl font-medium text-gray-900">
                            {post.title}
                          </p>
                          <p className="mt-3 text-base text-gray-500">
                            {post.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12 text-center">
                <button
                  onClick={() => setStages("addusers")}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Get started
                </button>
              </div>
            </>
          )}
          {stages === "addusers" && (
            <>
              <Subheader
                title="Welcome to Qonvr"
                description="Invest in quality conversations to progress on development goals"
              />

              <Manage />
              {/* <div className="rounded-md bg-blue-50 p-4 mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      You can add or remove users later on. This would help you
                      to start your team.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12 text-center">
                <button
                  onClick={() => setStages("selectplan")}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Select plan
                </button>
              </div>
            </>
          )}

          {stages === "selectplan" && (
            <>
              <Subheader
                title="Select a Development Cadence for your whole team"
                description="How often do you want your development conversations to be?"
              />
              <div className="grid grid-cols-12 gap-6 mt-12">
                <div className="col-span-12 lg:col-span-12 ">
                  

                  <div className="mb-12 space-y-4  sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                    {tiers.map((tier) => (
                      <div key={tier.title} className="relative">
                        <div
                          key={tier.title}
                          className={`${tier.themeClass} relative shadow sm:rounded-md`}
                        >
                          <div className="p-6 pb-4 pt-6">
                            <img src={tier.imageUrl} alt="" className="h-28" />
                            <h2 className="mt-5 text-2xl leading-6 font-medium text-black">
                              {tier.title}
                            </h2>
                            <p className="mt-5 text-sm text-black h-16">
                              {tier.description}
                            </p>
                          </div>
                          <div className="pt-2 px-6">
                            <h3 className="text-xs font-medium text-black tracking-wide uppercase">
                              What's included
                            </h3>
                            <ul className="mt-6 space-y-4">
                              {tier?.inclusions?.map((feature) => (
                                <li key={feature} className="flex space-x-3">
                                  <CheckIcon
                                    className="flex-shrink-0 h-5 w-5 text-black"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm text-black">
                                    {feature}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="pt-6 pb-6 px-6">
                            <button
                              className="mt-5 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              onClick={(e) => {
                                setManagerPlan("manager", tier.id);
                                setConfirmPopup('managerSelected');
                                setIsOpen(true);
                              }}
                            >
                              Select
                            </button>
                            <Popover className="relative">
                              <Popover.Button className="text-xs font-medium text-gray-900 tracking-wide uppercase p-6 pb-0 pl-0 flex items-center">
                                More details
                                <ArrowNarrowRightIcon
                                  className="flex-shrink-0 h-5 w-5 ml-2"
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                              <Popover.Overlay className="opacity-30 fixed inset-0 bg-black" />
                              <Popover.Panel className="absolute z-50 bottom-0 left-32 bg-white shadow w-80 sm:rounded-md  bg-white">
                                <ul className="mt-6 space-y-4 pt-0 p-6">
                                  {tier?.moreDetails?.map((details) => (
                                    <li
                                      key={details}
                                      className="flex space-x-3"
                                    >
                                      <CheckIcon
                                        className="flex-shrink-0 h-5 w-5 text-black"
                                        aria-hidden="true"
                                      />
                                      <span className="text-sm text-black">
                                        {details}
                                      </span>
                                    </li>
                                  ))}
                                </ul>

                                <img src="/solutions.jpg" alt="" />
                              </Popover.Panel>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div><div className="text-center">
                  <p className="mb-12 text-lg">
                      Or, select a plan for you team.
                    </p>
                    <button
                      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        setManagerPlan("endUser");
                        setConfirmPopup('userSelected');
                        setIsOpen(true);
                      }}
                    >
                      I prefer to let my team members select
                    </button>
                    <span className="flex justify-center mt-3 items-center text-gray-500">
                      <LightBulbIcon
                        className="flex-shrink-0 h-5 w-5 mr-1"
                        aria-hidden="true"
                      />
                      <p className="text-sm">
                        Having the team member select their plan increases the
                        ownership of their development
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-xl rounded bg-white p-5 text-center">
          <Dialog.Title className='text-2xl font-medium mb-3'>Great!</Dialog.Title>
          <Dialog.Description className='mb-8'>
            {confirmPopup === 'managerSelected' ? 'You have selected a development cadence for all your team members.' : 'You have decided to let your team members select their own cadence.'}
          </Dialog.Description>
            <button className="mb-5 inline-flex justify-center items-center rounded-md border border-transparent bg-green-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" onClick={() => { 
              navigate("/dashboard");
              setOnboardingStatus();
            }}>Let's start!</button>
          </Dialog.Panel>
        </div>
    </Dialog>
    </>
  );
};

export default OnboardingManagers;
