import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../context/profileProvider";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { faCheck, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faCircleXmark,
  faExclamationCircle,
  faLightbulbOn,
  faPlus,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { IGoal, IGoalSetting, IGoalSteps } from "../interface/generic";
import { Link, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import Popup from "../components/popup";
import Loading from "../components/loading";
const axios = require("axios");
export interface IProps {
  title?: string;
  isManager?: boolean;
}

interface IGoalSettingStatus {
  status: number
}

type LocationProps = {
  state: {
    data: any;
    status: any;
    id: any;
  };
};

const GoalSetting = (props: IProps) => {
  const token = localStorage.getItem("token");
  const goalSettingLocal = localStorage.getItem("goalSetting");
  const goalSettingLocalProcessed = JSON.parse(goalSettingLocal);
  const { userProfile } = useContext(ProfileContext);
  const [goalDetails, setGoalDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [goalIsOpen, setGoalIsOpen] = useState(false);
  const [stage, setStage] = useState("prepare");
  const [goalSetting, setGoalSetting] = useState<IGoalSetting>();
  const [agreementDate, setAgreementDate] = useState();
  const [agreementId, setAgreementId] = useState();
  const [futureSteps, setFutureSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState<IGoalSteps>();
  const [currentFutureStep, setCurrentFutureStep] = useState<IGoalSteps>();
  const [goalSteps, setGoalSteps] = useState([]);
  const [goals, setGoals] = useState<IGoal>();
  const [goalSettingDiscussion, setGoalSettingDiscussion] = useState(false);
  const [goalDetailsLoading, setGoalDetailsLoading] = useState(false);
  const [discussSectionError, setDiscussSectionError] = useState([]);
  const [goalSectionError, setGoalSectionError] = useState([]);
  const [accordionId, setAccordionId] = useState("");
  const [goalItems, setGoalItems] = useState([]);
  const [goalStage, setGoalStage] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState<IGoalSettingStatus>();
  const [goalSettingId, setGoalSettingId] = useState()
  const [viewOnlyLoading, setViewOnlyLoading] = useState(false)
  const [goalGlobalError, setGoalGlobalError] = useState({
    type: "",
    message: "",
  });
  const [isEditGoal, setIsEditGoal] = useState({
    status: false,
    item: "",
  });

  const location = useLocation() as unknown as LocationProps;
  const dateFromConv = location.state?.data
  const convStatus = location.state?.status
  const agreementIdProps = location.state?.id

  console.log(location)

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const toggleDisclosure = (key: string) => {
    setAccordionId((prev) => (prev !== key ? key : ""));
  };

  const checkCurrentGoalDiscussion = (agreementId) => {
    setViewOnlyLoading(true)
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversation/goal-setting/list/${agreementId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.data
        console.log(data)
        if(data) {
          setGoalSetting({ ...goalSetting, current_situation: data.current_situation, future_hope: data.future_hope, steps: [...data.steps]})
          checkCurrentGoalDiscussionStatus(agreementId, data.goal_conversation_id)
        }
        setViewOnlyLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setViewOnlyLoading(false)
      });
  }

  const checkCurrentGoalDiscussionStatus = (id, anotherId) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/conversations/${id}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data
        const filteredData = data.agreement.goal_conversations.filter(item => item.id === anotherId)
        setIsViewOnly(filteredData[0])
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  const checkGoalDate = (date) => {
    const then = new Date(date);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to days                 hour   min  sec   ms
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

    if (daysBetweenDates > 30) {
      // //console.log('beyond 30 days')
      return true;
    } else {
      // //console.log("less 30 days");
      setGoalGlobalError({
        ...goalGlobalError,
        type: "goalDate",
        message: "Your goal due date may not be less than 30 days",
      });
      return false;
    }
  };

  const getGoals = (agreementId) => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/goals/list/${agreementId}`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data[0];
        const goalList:any = Object.values(data.goals)

        setGoalDetails(goalList.filter(item => item.agreement_id === agreementId));
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setAgreementId(data.agreements[0].id);
        setAgreementDate(data.agreements[0].date);
        getGoals(agreementIdProps);
        convStatus === 1 && checkCurrentGoalDiscussion(agreementIdProps);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const processStep = (type) => {
    if (type === "step") 
      if (currentStep) setGoalSteps((goalSteps) => [...goalSteps, currentStep]);
    if (type === "futureStep")
      if (currentFutureStep) setFutureSteps((futureSteps) => [...futureSteps, currentFutureStep]);
  };

  const createGoal = (flag?) => {
    setGoalDetailsLoading(true);
    let urlString = `https://api.qonvr.co/api/goals/create/done`;
    axios({
      method: "post",
      url: urlString,
      crossDomain: true,
      data: goalItems,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setGoalDetailsLoading(false);
        setOpenConfirmPopup(true);
        localStorage.removeItem("goalSetting");
        // //console.log(data);
      })
      .catch(function (error) {
        // handle error
        //console.log(error.response.data);
        setGoalSectionError(
          error.response.data.message
            ? (result) => [...result, error.response.data.message]
            : Object.values(error.response.data.errors)
        );
        setGoalDetailsLoading(false);
      });
  };

  const checkGoalDiscuss = () => {
    if (discussSectionError.length > 0) {
      setDiscussSectionError((result) => [...result, "You still have issues"]);
    } else {
      !props.isManager && createGoalSetting();
      scrollTo("agree");
      setGoalStage("agree");
    }
  };

  const createGoalSetting = () => {
    axios({
      method: "post",
      url: `https://api.qonvr.co/api/conversation/goal-setting`,
      crossDomain: true,
      data: goalSetting,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        setIsOpen(false);
        setGoalSettingDiscussion(true);
        setDiscussSectionError([]);
        toggleDisclosure("4");
        // //console.log(data);
      })
      .catch(function (error) {
        // handle error
        //console.log(error.response.data);
        setDiscussSectionError(
          error.response.data.message
            ? (result) => [...result, error.response.data.message]
            : Object.values(error.response.data.errors)
        );
      });
  };

  const getCurrentGoal = (item) => {
    goalItems.map((e) => {
      if (e.name === item) {
        // //console.log(e);
        setGoalIsOpen(true);
        setGoals(e);
        setStage("goal-1");
      }
    });
  };

  const checkGoalState = () => {
    if (goalSteps.length > 0) {
      if (isEditGoal.status) {
        const newItems = goalItems.map((item) => {
          if (isEditGoal.item === item.name) {
            return {
              ...item,
              name: goals.name,
              steps: goals.steps,
              description: goals.description,
              measuring_success: goals.measuring_success,
              completion_date: goals.completion_date,
              date: goals.date,
              agreement_id: goals.agreement_id,
            };
          }
          return item;
        });
        setGoalItems(newItems);
        setIsEditGoal({ ...isEditGoal, status: false });
        setGoalIsOpen(false);

      } else {
        setGoalItems((goalItems) => [...goalItems, goals]);
        setIsEditGoal({ ...isEditGoal, status: false });
        setGoalIsOpen(false);
      }
    } else {
      setGoalGlobalError({
        ...goalGlobalError,
        type: "goalSteps",
        message: "We need some opportunities to complete your goal",
      });
    }
  };

  const removeGoal = () => {
    if (isEditGoal.status) {
      setGoalItems(goalItems.filter((item) => item.name !== isEditGoal.item));
      setIsEditGoal({ ...isEditGoal, status: false });
      setGoalIsOpen(false);
    }
  };

  const removeAction = (steps) => {
    setFutureSteps(futureSteps.filter((item) => item !== steps));
  };

  const removeStep = (step) => {
    setGoalSteps(goalSteps.filter((item) => item !== step));
  };

  const scrollTo = (id) => {
    const elId = document.getElementById(id);
    const elOffset = elId.offsetTop;

    window.scrollTo({
      top: elOffset,
      behavior: "smooth",
    });
  };

  const scrollDetection = () => {
    const scrollBarPosition = window.pageYOffset | document.body.scrollTop;

    //console.log(scrollBarPosition);

    // At specifiv position do what you want
    if (scrollBarPosition > 262) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    getUserProfile();
    toggleDisclosure("1");
    console.log(goalItems)
    // window.onscroll = function () {
    //   scrollDetection();
    // };
    return () => {
      toggleDisclosure("");
    };
  }, []);

  useEffect(() => {
    setGoals({ ...goals, steps: goalSteps });
  }, [goalSteps]);

  useEffect(() => {
    setGoalSetting({ ...goalSetting, steps: futureSteps });
  }, [futureSteps]);


  return (
  <>
    {viewOnlyLoading ? <div className="mt-12"><Loading text="Checking any existing conversation..." /></div> :
    <>
      <div className="mt-12 mb-16 flex justify-between">
        <div>
          <h2 className="text-4xl font-medium pb-4 text-slate-900">
            Goal Setting
          </h2>
          {!props.isManager && <h3 className="text-xl">
            A conversation with
            <strong className="ml-1">{userProfile?.manager[0]?.firstname}</strong>
          </h3> }
        </div>
        <div>
        {!props.isManager &&
          <button
            type="button"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-gray-300"
            onClick={(e) => {
              createGoal();
            }}
            disabled={isViewOnly?.status === 1}
          >
            <FontAwesomeIcon className="mr-2" icon={faCheck as IconProp} />
            Complete conversation
          </button>
}
        </div>
      </div>

      <div className={`${isScrolled && "bg-white fixed top-0 w-full left-0"}`}>
        <ul className="bg-white rounded-lg grid grid-cols-4 gap-4">
          <li
            className={`text-center py-5 ${
              goalStage === "prepare" && "border-b-4 border-blue-500 rounded-l-lg"
            }`}
          >
            Prepare
          </li>
          <li
            className={`text-center py-5 ${
              goalStage === "discuss" && "border-b-4 border-blue-500 "
            }`}
          >
            Discuss
          </li>
          <li
            className={`text-center py-5 ${
              goalStage === "agree" && "border-b-4 border-blue-500 "
            }`}
          >
            Agree
          </li>
          <li
            className={`text-center py-5 ${
              goalStage === "complete" && "border-b-4 border-blue-500  rounded-r-lg"
            }`}
          >
            Complete Goal Setting
          </li>
        </ul>
      </div>
      <div className="bg-slate-200 grid grid-cols-12 p-8 rounded-lg content-center mb-16 mt-16">
        <div className="col-span-12">
          <Disclosure>
            {({ open }) => (
              <>
                <Transition
                  show={true}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="text-gray-500 mb-5 mt-3">
                    <div className="grid grid-cols-12 gap-8">
                      <div className="col-span-2">
                        <img src="discuss-goal-conv.png" alt="" />
                      </div>
                      <div className="col-span-10">
                        <span className="py-2 text-left text-3xl font-medium text-blue-900 w-full block">
                          Talking point - and the science behind it
                        </span>
                        <p className="font-medium text-black mb-2">
                          An honest self-reflection of your development so far
                        </p>
                        <p className="mb-8 text-black">
                          Self-reflection is a process of careful thought that
                          gives the brain an opportunity to pause, sort through
                          experiences and helps gather insight about ourselves.
                          People who reflect frequently perform better because
                          it helps improve planning and decision making
                        </p>
                        <button
                          onClick={() => {
                            toggleDisclosure("2");
                            setGoalStage("prepare");
                            scrollTo("prepare");
                          }}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Let's start
                        </button>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div
        className={`bg-slate-200 grid grid-cols-12 p-8 rounded-lg content-center mb-16`}
        id="prepare"
      >
        <div className="col-span-12">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className="py-2 text-left text-3xl font-medium text-blue-900 w-full block"
                  onClick={() => toggleDisclosure("2")}
                >
                  <span>Prepare</span>

                </Disclosure.Button>
                <Transition
                  show={true}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="text-gray-500 mb-5 mt-3">
                    <p className="mb-8">
                      Get ready for discussion by spending time on
                      self-reflection on your current situation, writing down
                      your strengths and exploring your development goals
                    </p>

                    <button
                      onClick={() => {
                        toggleDisclosure("3");
                        setGoalStage("discuss");
                        scrollTo("discuss");
                      }}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      OK
                    </button>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div
        className={`bg-slate-200 grid grid-cols-12 p-8 rounded-lg content-center mb-16`}
        id="discuss"
      >
        <div className="col-span-12">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`py-2 text-left text-3xl font-medium text-blue-900 w-full block`}
                  onClick={() => toggleDisclosure("3")}
                >
                  <span>Discuss</span>
                </Disclosure.Button>

                <Transition
                  show={true}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="text-gray-500 mb-5 mt-5">
                    <ul className="mb-4">
                      <li>
                        <button
                          onClick={(e) => {
                            setIsOpen(true);
                            setStage("prepare");
                          }}
                          className={`items-center inline-flex pl-5 w-full mb-4 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            goalSetting?.current_situation &&
                            `text-lime-700 border-lime-700`
                          }`}
                        >
                          {goalSetting?.current_situation ? (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faCheck as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faChevronRight as IconProp}
                            />
                          )}
                          Current situation
                        </button>
                      </li>
                      <li>
                        <button
                          className={`items-center inline-flex pl-5 w-full mb-4 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            goalSetting?.future_hope &&
                            `text-lime-700 border-lime-700`
                          }`}
                          onClick={(e) => {
                            setIsOpen(true);
                            setStage("discuss");
                          }}
                        >
                          {goalSetting?.future_hope ? (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faCheck as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faChevronRight as IconProp}
                            />
                          )}
                          Future state
                        </button>
                      </li>
                      <li>
                        <button
                          className={`items-center inline-flex pl-5 w-full mb-4 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            goalSetting?.steps?.length >= 1 &&
                            `text-lime-700 border-lime-700`
                          }`}
                          onClick={(e) => {
                            setIsOpen(true);
                            setStage("agree");
                          }}
                        >
                          {goalSetting?.steps?.length >= 1 ? (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faCheck as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faChevronRight as IconProp}
                            />
                          )}
                          Opportunity
                        </button>
                      </li>
                    </ul>
                    {discussSectionError.length >= 1 && (
                      <div className="rounded-md bg-red-50 p-4 mb-8">
                        <div className="flex">
                          <div className="text-sm text-red-700">
                            <ul className="list-disc pl-5 space-y-1">
                              {discussSectionError?.map((item, i) => {
                                return <li key={i}>{item}</li>;
                              })}
                            </ul>
                          </div>
                          <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                              <button
                                type="button"
                                onClick={(e) => setDiscussSectionError([])}
                                className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                              >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      onClick={(e) => {
                        checkGoalDiscuss();
                      }}
                      disabled={isViewOnly?.status === 1}
                      className="disabled:bg-gray-300 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Proceed to next step
                    </button>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div
        className="bg-slate-200 grid grid-cols-12 p-8 rounded-lg content-center mb-16"
        id="agree"
      >
        <div className="col-span-12">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className="py-2 text-left text-3xl font-medium text-blue-900 w-full block"
                  onClick={() => toggleDisclosure("4")}
                >
                  <span>Define and agree on your goals</span>
                </Disclosure.Button>
                <Transition
                  show={true}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="text-gray-500 mb-5 mt-3">
                    {goalDetailsLoading}
                    <div className="mb-5">
                      {goalItems?.map((item, i) => {
                        return (
                          <span key={i}>
                            <button
                              onClick={(e) => {
                                getCurrentGoal(item.name);
                                setIsEditGoal({
                                  ...isEditGoal,
                                  status: true,
                                  item: item.name,
                                });
                              }}
                              className="items-center inline-flex pl-5 w-full mb-4 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 false"
                            >
                              {item.name}
                            </button>
                          </span>
                        );
                      })}
                    </div>
                    {goalDetails?.map((goal: IGoal, i) => {
                      return (
                        <div className="bg-gray-100 p-5 mb-5" key={i}>
                          <p className="text-md font-medium text-gray-900">
                            {goal.name}
                          </p>
                          <span className="text-sm text-gray-500 bg-white px-3 py-1 inline-flex my-2">
                            Completion date
                            {new Date(goal.completion_date).toLocaleDateString(
                              "en-AU"
                            )}
                          </span>
                          <p className="text-sm text-gray-500">
                            {goal.description}
                          </p>
                        </div>
                      );
                    })}

                    <button
                      onClick={(e) => {
                        setStage("goal-1");
                        setGoalIsOpen(true);
                        /* reset goals */
                        setGoals({});
                        setGoalSteps([]);
                        setCurrentStep({});
                      }}
                      disabled={isViewOnly?.status === 1}
                      className="disabled:bg-gray-300 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faPlus as IconProp}
                      />
                      Add new goal
                    </button>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div className="pb-12">
        {goalSectionError.length >= 1 && (
          <div className="rounded-md bg-red-50 p-4 mb-5">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  There was an issue with your submission
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc pl-5 space-y-1">
                    {goalSectionError.map((item, i) => {
                      return <li key={i}>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={(e) => setGoalSectionError([])}
                    className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
                {!props.isManager &&
        <button
          type="button"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-gray-300"
          onClick={(e) => {
            createGoal();
          }}
          disabled={isViewOnly?.status === 1}
        >
          <FontAwesomeIcon className="mr-2" icon={faCheck as IconProp} />
          Complete conversation
        </button>
}
      </div>

      <Popup
        title="Excellent"
        description="You have completed Goal setting. Lets start the conversation!"
        open={openConfirmPopup}
        openFunc={setOpenConfirmPopup}
        close="/dashboard"
      />

      <Dialog open={isOpen} onClose={() => {}} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto container rounded-lg bg-white relative">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute -right-4 -top-4 rounded-full bg-red-500 w-8 h-8 text-white"
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </button>
            {stage === "prepare" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 bg-blue-100 p-6 rounded-l-lg">
                    <img src="/plan-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      If you have not self-reflected before, start with a short
                      10 mins session. Self-reflection doesn't need to take a
                      long time to be useful.
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      Let's start with your current situation. Where are you at
                      now in terms of development? What are you missing? What's
                      not working?
                    </h2>
                    <p className="mb-8 text-gray-500">
                      Reflect and have an honest discussion about your current
                      skills, capabilities, mental abilities and experience..
                      Write them down.
                    </p>
                    <textarea
                      id="about"
                      name="about"
                      rows={8}
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                      placeholder=""
                      value={goalSetting?.current_situation || ""}
                      onChange={(e) => {
                        setGoalSetting({
                          ...goalSetting,
                          current_situation: e.currentTarget.value,
                        });
                      }}
                    />
                    {goalGlobalError.type === "currentSituation" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}
                    <div className="flex justify-end mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                        onClick={(e) => {
                          goalSetting.current_situation &&
                          goalSetting.current_situation.length >= 5
                            ? setStage("discuss")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "currentSituation",
                                message:
                                  "Your current situation is still empty or having less words (min 5 characters), perhaps add some more details.",
                              });
                          // saveStorage();
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {stage === "discuss" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 bg-blue-100 p-6 rounded-l-lg">
                    <img src="/discuss-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      Take into account your strengths. For example, a good
                      orator should set goals to flourish as a speaker, while an
                      expressive writer must aim to succeed as an author.
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      Where do you want to be in the near future? (In 1-2 years)
                    </h2>
                    <p className="mb-8 text-gray-500">
                      Write down the next role, level or experience you want to
                      reach or obtain in this timeframe. Discuss and write down
                      which is the most desirable one for you.
                    </p>
                    <textarea
                      id="about"
                      name="about"
                      rows={8}
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                      placeholder="Example:
                        - I want to be in a people management position in 2 years time.
                        - Its is desireable for me because I want to manage a team, increase my salary and increase my influence in product decisions"
                      value={goalSetting?.future_hope || ""}
                      onChange={(e) =>
                        setGoalSetting({
                          ...goalSetting,
                          future_hope: e.currentTarget.value,
                        })
                      }
                    />

                    {goalGlobalError.type === "futureState" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("prepare")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          goalSetting.future_hope &&
                          goalSetting.future_hope.length >= 5
                            ? setStage("agree")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "futureState",
                                message:
                                  "Your future state is still empty or having less words (min 5 characters), we recommend adding some more details.",
                              });
                          // saveStorage()
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {stage === "agree" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 bg-blue-100 p-6 rounded-l-lg">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      className="mt-5"
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      Take into account what worked and what didn't in your
                      self-reflection. These insights will help you set the
                      actions that ensures progress.
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      How are you going to reach your future state?
                    </h2>
                    <p className="mb-8 text-gray-500">
                      Discuss what new responsibilities, habits, skills or
                      qualifications you need to develop or obtain to bridge the
                      gap between current and future. Write down the key ones.
                      These will become your key development opportunities.
                    </p>
                    <p className="font-medium ">Example:</p>
                    <ul className="mb-5">
                      <li>
                        - Improve communication to both senior management
                        stakeholders and team members
                      </li>
                      <li>
                        - Learn how to coach team members and manage their
                        growth
                      </li>
                      <li>- Get a certification for people management</li>
                      <li>- Learn more about the product</li>
                    </ul>

                    <div className="rounded-md p-4 bg-gray-100  mt-5">
                      <div className="sm:flex sm:items-center">
                        <div className="w-9/12">
                          <input
                            type="text"
                            className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Add some opportunity"
                            value={currentFutureStep?.title || ""}
                            onChange={(e) =>
                              setCurrentFutureStep({...currentFutureStep, title: e.currentTarget.value, description: e.currentTarget.value, status: false})
                            }
                          />
                        </div>
                        <button
                          type="button"
                          onClick={(e) => {
                            processStep("futureStep");
                            setCurrentFutureStep({});
                            setGoalSetting({
                              ...goalSetting,
                              date: agreementDate,
                              agreement_id: agreementId,
                            });
                          }}
                          className="mt-3 w-3/12 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:text-sm"
                        >
                          Add an opportunity
                        </button>
                      </div>
                    </div>
                    {goalSetting?.steps?.length >= 1 && (
                      <div className="rounded-md p-4 border-2 border-gray-200  mt-3 mb-8">
                        <ul className="divide-y divide-gray-300">
                          {goalSetting?.steps?.map((step, i) => (
                            <li
                              key={i}
                              className="relative py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 text-sm "
                            >
                              <span className="flex items-center justify-between">
                                {step.title}
                                <button
                                  onClick={() => {
                                    removeAction(step);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="text-red-500 text-base"
                                    icon={faCircleXmark as IconProp}
                                  />
                                </button>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {goalGlobalError.type === "futureActions" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("discuss")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={(e) => {
                          goalSetting?.steps?.length > 0
                            ? setIsOpen(false)
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "futureActions",
                                message:
                                  "We dont have any actions yet for your discussion.",
                              });
                          // saveStorage();
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Complete
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog open={goalIsOpen} onClose={() => {}} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto container rounded-lg bg-white relative">
            <button
              onClick={() => setGoalIsOpen(false)}
              className="absolute -right-4 -top-4 rounded-full bg-red-500 w-8 h-8 text-white"
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </button>
            {stage === "goal-1" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      When giving your goal a title, word it in such a way that
                      would make it inspiring, and that you feel excited and
                      scared at the same time. So that ...
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      Lets start with your first goal. Give your goal a title
                    </h2>

                    <textarea
                      id="about"
                      name="about"
                      rows={8}
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                      placeholder="Example: Become a storytelling master"
                      value={goals?.name || ""}
                      onChange={(e) =>
                        setGoals({ ...goals, name: e.currentTarget.value })
                      }
                    />
                    {goalGlobalError.type === "goalName" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}

                   
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-300"
                        onClick={(e) => {
                          removeGoal();
                        }}
                        disabled={!isEditGoal.status}
                      >
                        Remove Goal
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>

                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          goals.name
                            ? setStage("goal-2")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "goalName",
                                message: "Your goal name is empty",
                              });
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stage === "goal-2" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      Describe why it is important to you
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      Describe your goal
                    </h2>

                    <textarea
                      id="about"
                      name="about"
                      rows={8}
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                      placeholder="Example: 
                Master the art of storytelling in the situation that matters. Able to communicate simply and clearly to senior management and team members in showcases and key meetings"
                      value={goals?.description || ""}
                      onChange={(e) =>
                        setGoals({
                          ...goals,
                          description: e.currentTarget.value,
                        })
                      }
                    />
                    {goalGlobalError.type === "goalDesc" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}

                  
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("goal-1")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          goals.description
                            ? setStage("goal-3")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "goalDesc",
                                message: "Your goal description is empty",
                              });
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stage === "goal-3" && (
              <div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      If the goal is a soft skill or not quantifiable, write it
                      as an outcome or feeling. What would people say or do when
                      you achieve this goal? How would you feel if you achieve
                      it?
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      How are you measuring success?
                    </h2>

                    <textarea
                      id="about"
                      name="about"
                      rows={8}
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                      placeholder="Example: 
                I will be comfortable presenting in showcases and key steering committee meetings without feeling stunned or tongue-tied. I will get good feedback on how simply and clearly I can express the complex information in front of a big group"
                      value={goals?.measuring_success || ""}
                      onChange={(e) =>
                        setGoals({
                          ...goals,
                          measuring_success: e.currentTarget.value,
                        })
                      }
                    />
                    {goalGlobalError.type === "goalMeasure" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("goal-2")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          goals.measuring_success
                            ? setStage("goal-4")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "goalMeasure",
                                message:
                                  "How do you measure your success? its still empty.",
                              });
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stage === "goal-4" && (
              <div className="p-5">
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      Set a realistic time frame - we would recommend 30 days
                      minimum for achieving goal
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      When is the due date?
                    </h2>

                    <input
                      type="date"
                      name="date"
                      id="date"
                      className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md my-4"
                      aria-describedby="date"
                      value={goals?.completion_date || ""}
                      onChange={(e) => {
                        if (checkGoalDate(e.currentTarget.value)) {
                          setGoals({
                            ...goals,
                            completion_date: e.currentTarget.value,
                          });
                        }
                      }}
                    />

                    {goalGlobalError.type === "goalDate" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="mb-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}

                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("goal-3")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          goals.completion_date
                            ? setStage("goal-5")
                            : setGoalGlobalError({
                                ...goalGlobalError,
                                type: "goalDate",
                                message: "Your goal due date may not be empty",
                              });
                          setGoals({
                            ...goals,
                            date: agreementDate,
                            agreement_id: agreementId,
                          });
                        }}
                      >
                        Next
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stage === "goal-5" && (
              <div className="p-5">
                <div className="grid grid-cols-12 gap-10">
                  <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                    <img src="/set-goal-conv.png" alt="" className="mb-3" />
                    <Alert
                      className="mt-5"
                      severity="warning"
                      icon={
                        <FontAwesomeIcon icon={faLightbulbOn as IconProp} />
                      }
                    >
                      A goal can be overwhelming. Breaking it down into chunks
                      will help you see progress and achieving the goal more
                      likely.
                    </Alert>
                  </div>
                  <div className="col-span-9 p-10 pl-0">
                    <h2 className="font-medium mb-4 text-2xl">
                      What is the first step or action you have to take?
                    </h2>
                    <div className="bg-gray-100 rounded-md p-4 mt-5">
                      <div className="sm:flex sm:items-center">
                        <div className="w-9/12">
                          <input
                            type="text"
                            className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Your steps / action towards your goal"
                            value={currentStep?.title || ""}
                            onChange={(e) =>
                              setCurrentStep({...currentStep, title: e.currentTarget.value, description: e.currentTarget.value, status: false})
                            }
                          />
                        </div>
                        <button
                          type="button"
                          onClick={(e) => {
                            processStep("step");
                            setCurrentStep({});
                          }}
                          className="mt-3 w-3/12 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:text-sm"
                        >
                          Add step / action
                        </button>
                      </div>
                    </div>
                    {goals.steps.length >= 1 && (
                      <div className="border-2 border-gray-200 rounded-md p-4 mt-3 mb-8">
                        <ul className="divide-y divide-gray-300">
                          {goals?.steps?.map((step, i) => (
                            <li
                              key={i}
                              className="relative py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 text-sm "
                            >
                              <span className="flex items-center justify-between">
                                <p>{step.title}</p>
                                <button
                                  onClick={() => {
                                    removeStep(step);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="text-base text-red-500"
                                    icon={faCircleXmark as IconProp}
                                  />
                                </button>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {goalGlobalError.type === "goalSteps" && (
                      <Alert
                        onClose={() => {
                          setGoalGlobalError({
                            ...goalGlobalError,
                            type: "",
                            message: "",
                          });
                        }}
                        className="my-5"
                        severity="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                          />
                        }
                      >
                        {goalGlobalError.message}
                      </Alert>
                    )}
      
                    <div className="flex justify-between mt-8">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => setStage("goal-4")}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faArrowLeft as IconProp}
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={(e) => {
                          checkGoalState();
                        }}
                      >
                        {isEditGoal.status
                          ? "Update this goal"
                          : "Create this goal"}
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faArrowRight as IconProp}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stage === "end-goal" && (
              <div className="p-5">
                <div className="text-center">
                  <img
                    src="/set-goal-conv.png"
                    alt=""
                    className="w-64 m-auto"
                  />
                  <h1 className="text-3xl mb-3 mt-8">Success!</h1>
                  <p className="text-gray-500 mb-8">
                    Your first check-in will be in X weeks on dd/mm/yy
                  </p>
                  <a
                    href="/dashboard"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Go to Dashboard
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faArrowRight as IconProp}
                    />
                  </a>
                </div>
              </div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
    }</>
  );
};

export default GoalSetting;
