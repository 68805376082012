import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProfileContext } from "../context/profileProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import {
  faSignOut,
  faUsers,
  faUserCog,
} from "@fortawesome/pro-duotone-svg-icons";
export interface IProps {
  title?: string;
  page?: pages;
  toggleSidebar?: boolean;
  agreementStatus?: string;
  onboardingStatus?: boolean;
}
export type pages = "generic" | "login";

const user = {
  name: "Henry",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = (props: IProps) => {
  const { userProfile } = useContext(ProfileContext);
  const location = useLocation();

  const currentPathName = location.pathname;

  const checkSelectedNav = (pathValue, currentValue) => {
    if (pathValue === currentValue) {
      return true;
    }
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      current: checkSelectedNav(currentPathName, "/dashboard"),
    },
    {
      name: "Agreement",
      href: "/agreement",
      current: checkSelectedNav(currentPathName, "/agreement"),
    },
    {
      name: "Goals",
      href: "/goals",
      current: checkSelectedNav(currentPathName, "/goals"),
    },
    {
      name: "Conversations",
      href: "/conversations",
      current: checkSelectedNav(currentPathName, "/conversations"),
    },
  ];

  const signoutFunction = () => {
    if (userProfile) {
      localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <>
      <div className="bg-white border-gray border-b">
        <div
          className={`${
            !props.onboardingStatus
              ? "pl-8"
              : props.toggleSidebar
              ? "pl-24"
              : "pl-64"
          } mx-auto pr-8`}
        >
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <p className="text-gray-400">{userProfile.organisation.name}</p>
            </div>
            <div className="block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="flex">
                  <NavLink
                    to="/profile"
                    className={({ isActive }) =>
                      isActive
                        ? "text-slate-800 block px-4 py-2 text-md font-medium"
                        : "block px-4 py-2 text-md text-gray-400 hover:text-black"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUserCog as IconProp}
                      className="mr-1 md:hidden"
                    />
                    <span className="hidden md:inline-block">Settings</span>
                  </NavLink>
                  {userProfile.user_type === "manager" && (
                    <NavLink
                      to="/manage-users"
                      className={({ isActive }) =>
                        isActive
                          ? "text-slate-800 block px-4 py-2 text-md font-medium"
                          : "block px-4 py-2 text-md text-gray-400 hover:text-black"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faUsers as IconProp}
                        className="mr-1 md:hidden"
                      />
                      <span className="hidden md:inline-block">
                        Manage User
                      </span>
                    </NavLink>
                  )}
                  <NavLink
                    to="/logout"
                    onClick={(e) => signoutFunction()}
                    className={({ isActive }) =>
                      isActive
                        ? "text-slate-800 block px-4 py-2 text-md font-medium"
                        : "block px-4 py-2 text-md text-gray-400 hover:text-black"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faSignOut as IconProp}
                      className="mr-1 md:hidden"
                    />
                    <span className="hidden md:inline-block">Sign out</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
