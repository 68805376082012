import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulbOn } from "@fortawesome/pro-light-svg-icons";
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface IProps {
    title?: string
  }
  const Tip = 'When having a discussion, don’t ask multiple questions at a time. Ask one question at a time instead and make sure to provide time to think'
  const Tips = (props: IProps) => {
  

    return (

        <div className="sm:rounded-md mb-6 p-6 bg-gradient-to-r from-sky-500 to-indigo-500 h-full">

            <img src="/discussion.png" />
            <p className='text-white'>{Tip}</p>
            <div className='text-right'>
            {/* <a
          href="/"
          className="pt-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Next
          <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a> */}
            </div>
        </div>
    )
  }
  
  export default Tips