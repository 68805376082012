/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { IUser } from "../interface/generic";
import { useNavigate, Link } from "react-router-dom";
import API from "./../api/api";
import Loading from "./loading";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Snackbar } from "@mui/material";
export interface IProps {
  title?: string;
  email?: string;
  password?: string;
  setValidLogin?: Function;
  setToken?: Function;
  resetPasswordDetail?: string;
  isActivation?: boolean;
  isResetPassword?: boolean;
  loginString?: string;
  userActivationDetail?: IUser;
  invalidActivation?: boolean;
  isRegistration?: boolean;
  setIsRegistration?: Function;
  managerActivation?: boolean;
}
const Login = (props: IProps) => {
  const axios = require("axios");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [registrationApiError, setRegistrationApiError] = useState([]);
  const [registrationApiSuccess, setRegistrationApiSuccess] = useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({
    firstname: "",
    lastname: "",
    email_address: "",
    password: "",
    confirm_password: "",
    user_type: "manager",
    organisation_name: ""
  })
  const [isError, setIsError] = useState({
    title: "",
    description: "",
  });
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [activationDetails, setActivationDetails] = useState({
    password: null,
    confirm_password: null,
    user_id: null,
  });
  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    password: null,
    confirm_password: null,
    token: props.resetPasswordDetail
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };


  const registerAccountFunction = () => {
    setRegistrationLoading(true);
    axios({
      method: "post",
      url: `https://api.qonvr.co/api/register`,
      crossDomain: true,
      data: registrationDetails
    })
      .then(function (response) {
        console.log(response)
        setRegistrationApiSuccess(true)
        setRegistrationLoading(false)
      })
      .catch(function (error) {
        // handle error
        setRegistrationLoading(false)
        console.error(error.response.data.errors);
        setRegistrationApiError(Object.values(error.response.data.errors))
      });
  }

  const loginFunction = async (email?, password?) => {
    setIsLoading(true);

    if (loginDetails.email === "" || loginDetails.password === "") {
      setIsError({
        ...isError,
        title: "Login error",
        description: "Login details cannot be empty",
      });
      setShowToast(true);
      setIsLoading(false);
    } else {
      try {
        const response = await API.post(`/login`, {
          email_address: email,
          password: password,
        });
        if (response) {
          props.setValidLogin(true);
          props.setToken(localStorage.getItem("token"));
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("profile", JSON.stringify(response.data));
          setIsLoading(false);
        }
      } catch (error) {
        // Handle Error Here
        setIsLoading(false);
        setShowToast(true);
        setIsError({
          ...isError,
          title: error.message,
          description: error.response.data.message,
        });
      }
    }
  };

  const activateLogin = async () => {
    try {
      const response = await API.post(
        `/invite/create/${props.userActivationDetail.email_verification_token}`,
        {
          confirm_password: activationDetails.confirm_password,
          password: activationDetails.password,
          user_id: props.userActivationDetail.id,
        }
      );
      if (response) {
        window.location.replace("/");
      }
    } catch (error) {
      // Handle Error Here
      setShowToast(true);
      setIsError({
        ...isError,
        title: error.response.status,
        description: error.message,
      });
    }
  };

  const resetPasswordAfterFunc = async () => {
    setInProgress(true);
    try {
      const response = await API.post(`/reset-password`, {
        password: resetPasswordDetails.password,
        confirm_password: resetPasswordDetails.confirm_password,
        token: resetPasswordDetails.token
      });
      if (response) {
        setResetPasswordSuccess(true);
        setInProgress(false);
      }
    } catch (error) {
      // Handle Error Here
      setShowToast(true);
      setIsError({
        ...isError,
        title: error.response.status,
        description: error.message,
      });
      setInProgress(false);
    }
  };
  
  const resetPasswordFunc = async () => {
    setInProgress(true);
    try {
      const response = await API.post(`/forgot-password`, {
        email_address: loginDetails.email,
      });
      if (response) {
        setResetPasswordSuccess(true);
        setInProgress(false);
      }
    } catch (error) {
      // Handle Error Here
      setShowToast(true);
      setIsError({
        ...isError,
        title: error.response.status,
        description: error.message,
      });
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (props.isResetPassword) setResetPassword(true);
    if (props.invalidActivation) setShowToast(true);
    setIsError({
      ...isError,
      title: "Invalid token",
      description: "Token is expired. Please get a new one.",
    });
  }, []);


  return (
    <>
    <div className="bg-white p-5"><img
    className="h-12 w-auto"
    src="/logo-color.png"
    alt="Workflow"
  /></div>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="w-full fixed"
      >
        <div className="fixed w-full left-0 ">
          <Alert variant="filled" severity="error">
            {isError.description}
          </Alert>
        </div>
      </Snackbar>
      {props.isRegistration ? (
         <>
          <div className="max-w-screen-lg mx-auto rounded-lg overflow-hidden mt-12">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="pt-0 px-10 md:p-10 order-2 md:order-2">
                    <p className="text-4xl text-slate-600">Create account</p>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                   { registrationLoading ? <div className="sm:col-span-6"><Loading text="Setting up your account..."></Loading></div> : 
                   registrationApiSuccess ? <div className="sm:col-span-6"><p className="mb-5">Great, your account has been setup. Check your email to confirm.</p><button
                  type="button"
                  onClick={(e) => {
                    setRegistrationApiError([]);
                    setRegistrationApiSuccess(false);
                    props.setIsRegistration(false);
                  }}
                  className="sm:col-span-3 content-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                 Login
                </button></div> : <>
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      value={registrationDetails.firstname || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, firstname: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="last-name"
                      value={registrationDetails.lastname || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, lastname: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={registrationDetails.email_address || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, email_address: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={registrationDetails.password || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, password: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="password-repeat" className="block text-sm font-medium text-gray-700">
                    Password Repeat
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password-repeat"
                      id="password-repeat"
                      value={registrationDetails.confirm_password || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, confirm_password: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="organisation" className="block text-sm font-medium text-gray-700">
                    Company / Organisation
                  </label>
                  <div className="mt-1">
                    <input
                      id="organisation"
                      name="organisation"
                      type="text"
                      autoComplete="organisation"
                      value={registrationDetails.organisation_name || ""}
                      onChange={(e) => setRegistrationDetails({...registrationDetails, organisation_name: e.target.value})}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={(e) => registerAccountFunction()}
                  className="sm:col-span-3 content-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Register
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    setRegistrationApiError([]);
                    setRegistrationApiSuccess(false);
                    props.setIsRegistration(false);
                  }}
                  className="sm:col-span-3 content-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Go to Login
                </button> </> }
                {registrationApiError.length >= 1 && 
                 <div className="rounded-md bg-red-50 p-4 sm:col-span-6">
                 <div className="flex">
                   <div className="flex-shrink-0">
                     <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                   </div>
                   <div className="ml-3">
                     <h3 className="text-sm font-medium text-red-800">There were {registrationApiError?.length} errors with your submission</h3>
                     <div className="mt-2 text-sm text-red-700">
                       <ul className="list-disc space-y-1 pl-5">
                        {registrationApiError?.map((item, i) => {
                          return (
                            <li key={i}>{item}</li>
                          )
                        })}
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>

                }
                </div>
              </div>
              <div className="p-10 pb-0 order-1 md:order-1 md:p-10 hidden md:block">
                {/* <p className="text-4xl text-center mb-12">Start your <span className="font-medium">quality</span> conversation.</p> */}
                <img
                  src="/register-banner-qonvr.png"
                  alt="Quality conversation"
                  className="mt-0 md:mt-12"
                />
              </div>
            </div>
          </div>
          </>
      
      ) : (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div className="w-full">
            
      
              <h2 className="mt-6 text-center text-4xl font-medium text-slate-600">
                {props.isActivation ? "Activate your account" : "Login"}
              </h2>
      
          </div>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto">
            <div className="px-4"><img className="w-4/5 mx-auto" src="/signin-banner-qonvr.png" alt="Sign in" /></div>
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">

            {props.managerActivation && props.isActivation ? <div className="text-center"><p className="text-center mb-5">Your account has been activated!</p><a className="block content-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2" href="/login">Login</a></div> :
              <form className="space-y-6" action="#" method="POST">
                {!resetPassword && (
                  <>
                    {props.isActivation && (
                      <div>
                        <label
                          htmlFor="activationCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your activation code
                        </label>
                        <div className="mt-1">
                          <input
                            id="activationCode"
                            name="activationCode"
                            type="text"
                            value={props.loginString}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-100"
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    {!props.invalidActivation && (
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                              props.isActivation && "bg-gray-100"
                            }`}
                            onChange={(e) =>
                              setLoginDetails({
                                ...loginDetails,
                                email: e.target.value,
                              })
                            }
                            value={
                              props.userActivationDetail
                                ? props.userActivationDetail.email_address
                                : loginDetails.email
                            }
                            disabled={props.isActivation}
                          />
                        </div>
                      </div>
                    )}
                    {!props.invalidActivation && (
                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => {
                              props.isActivation
                                ? setActivationDetails({
                                    ...activationDetails,
                                    password: e.target.value,
                                  })
                                : setLoginDetails({
                                    ...loginDetails,
                                    password: e.target.value,
                                  });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {props.isActivation && !props.invalidActivation && !props.managerActivation && (
                      <div>
                        <label
                          htmlFor="passwordRepeat"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Repeat Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="passwordRepeat"
                            name="passwordRepeat"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) =>
                              setActivationDetails({
                                ...activationDetails,
                                confirm_password: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    {!props.isActivation &&  (
                      <div className="flex items-center justify-between">
                        {/* <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <label
                            htmlFor="remember-me"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="text-sm ">
                          <a
                            href="#"
                            onClick={(e) => setResetPassword(true)}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            Forgot your password?
                          </a>
                        </div>
                      </div>
                    )}
                    <div>
                      {isLoading ? (
                        <Loading />
                      ) : props.invalidActivation ? (
                        <a href="/login"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Go back to Login
                        </a>
                      ) : (
                        <div className={`grid ${props.isActivation ? 'grid-cols-1' : 'grid-cols-2'} gap-4`}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            navigate('/dashboard')
                            props.isActivation && !props.managerActivation
                              ? activateLogin()
                              : loginFunction(
                                  loginDetails.email,
                                  loginDetails.password
                                );
                          }}
                          className="content-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {props.isActivation && !props.managerActivation
                            ? "Activate your account"
                            : "Sign in"}
                        </button>
                        {!props.isActivation && <button onClick={(e) => props.setIsRegistration(true)} className="content-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">Create an account</button>
                        }
                        </div>
                      )}
                    </div>
                  </>
                )}
                {resetPassword && (
                  <div>
                    {props.resetPasswordDetail.length > 1 ? (
                      <>
                        <div className="mb-4">
                          <label
                            htmlFor="resetPasswordActivation"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Your reset password code
                          </label>
                          <div className="mt-1">
                            <input
                              id="resetPasswordActivation"
                              name="resetPasswordActivation"
                              type="text"
                              value={props.resetPasswordDetail}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-100"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              onChange={(e) => {
                                setResetPasswordDetails({
                                  ...resetPasswordDetails,
                                  password: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm password
                          </label>
                          <div className="mt-1">
                            <input
                              id="confirm-password"
                              name="confirm-password"
                              type="confirm-password"
                              autoComplete="confirm-password"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              onChange={(e) => {
                                setResetPasswordDetails({
                                  ...resetPasswordDetails,
                                  confirm_password: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              resetPasswordAfterFunc();
                            }}
                            disabled={inProgress}
                            className="w-full items-center flex justify-center py-2 px-4 border border-transparent rounded-md shadow text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Reset password
                            {inProgress && (
                              <>
                                <svg
                                  role="status"
                                  className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </>
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className={`appearance-none mb-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm 
                      }`}
                            onChange={(e) =>
                              setLoginDetails({
                                ...loginDetails,
                                email: e.target.value,
                              })
                            }
                            value={
                              props.userActivationDetail
                                ? props.userActivationDetail.email_address
                                : loginDetails.email
                            }
                            disabled={props.isActivation || inProgress}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              resetPasswordFunc();
                            }}
                            disabled={inProgress}
                            className="w-full items-center flex justify-center py-2 px-4 border border-transparent rounded-md shadow text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Reset password
                            {inProgress && (
                              <>
                                <svg
                                  role="status"
                                  className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </>
                            )}
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setResetPassword(false);
                            }}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    {resetPasswordSuccess && (
                      <div className="rounded-md bg-green-50 p-4 mt-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-green-800">
                              Success. If we have that email registered, you
                              will receive an email on how to reset the
                              password.
                            </p>
                          </div>
                          <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                              <button
                                type="button"
                                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                              >
                                <span className="sr-only">Dismiss</span>
                                <XIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    setResetPassword(false);
                                    setResetPasswordSuccess(false);
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </form>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
