import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAgreement, IConversationFrequency, IOnboardingDeals } from "../interface/generic";
import { faHandsHelping } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { ProfileContext } from "../context/profileProvider";
import Loading from "../components/loading";
import { Dialog } from "@headlessui/react";
import { SettingsPowerRounded } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import {
    faArrowRight,
    faArrowLeft,
    faLightbulbOn,
    faCircleXmark,
    faInfoCircle,
  } from "@fortawesome/pro-light-svg-icons";

const axios = require("axios");

export interface IProps {
  title?: string;
}

type LocationProps = {
    state: {
      data: any;
      status: any;
      id: any;
      name: any;
    };
};

const ManagerReflection = (props: IProps) => {
  const token = localStorage.getItem("token");
  const [listAgreements, setListAgreements] = useState<IAgreement[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { userProfile } = useContext(ProfileContext);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation() as unknown as LocationProps;
  const dateFromConv = location.state?.data
  const convStatus = location.state?.status
  const agreementIdProps = location.state?.id
  const name = location.state?.name


  useEffect(() => {
    // getListAgreements();
    // console.log(dateFromConv, convStatus, agreementIdProps)
  }, []);

  
  return (
    <div className="mt-12">
      <h2 className="text-4xl font-medium pb-4 text-slate-900">
        Reflection conversation
      </h2>
      <div className="grid grid-cols-12 gap-4 bg-white mt-12 shadow rounded-md mb-12">
        <div className="py-12 px-5 col-span-4 text-center">
          <p className="text-xl mb-5">
            A Reflection with <span className="font-medium text-2xl">{name}</span>
          </p>
          <img
            src="/reflection-manager.png"
            alt="Reflection"
            className="w-full"
          /></div>
        <div className="p-12 col-span-8">
          <div className="bg-blue-100 p-5 inline-block mb-5">
            <p className="text-2xl mb-3">Lets start</p>
          <ul className="list-disc ml-5"><li>Follow the conversation structure below</li>
          <li>Ask these questions to your team member and have the necessary discussions. </li>
          <li>Team members should write down the discussion notes in their reflection conversation page   </li>
          </ul></div>
          <div className="bg-slate-100 p-8">
            <h2 className="text-2xl mb-2">
              In terms of your overall development progress, what has worked or
              helping so far?
            </h2>
            <p className="text-gray-500">
              Discuss and write down the positives and the things you that has
              helped or enabled you to progress
            </p>
          </div>
          <Alert
                  severity="warning"
                  icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  className="mb-12"
                >
                  Emphasise growth over speed - Learning fast isn't the same as learning well, and learning well sometimes requires allowing time for mistakes.
                </Alert>
          <div className="bg-slate-100 p-8 ">
            <h2 className="text-2xl mb-2">
              In terms of your overall development progress, what has not worked
              or not helping so far?
            </h2>
            <p className="text-gray-500">
              Discuss and write down challenges and the things you that has not
              helped or not enabled you to progress
            </p>
          </div>
          <Alert
                  severity="warning"
                  icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  className="mb-12"
                >
                 Replace the word “failing” with the word "learning" When a mistake is made or fall short of a goal, one haven't failed; one have  learned
                </Alert>
          <div className="bg-slate-100 p-8">
            <h2 className="text-2xl mb-2">
            Agree on what you should do <i>more</i> of
            </h2>
            <p className="text-gray-500">
            Write down the ideas and opportunities that you should do more of to progress
            </p>
          </div>
          <Alert
                  severity="warning"
                  icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  className="mb-12"
                >
                  When discussing opportunities: Learn from other people's mistakes - It's not always wise to compare yourself to others, but it is important to realise that humans share the same weaknesses.
                </Alert>
          <div className="bg-slate-100 p-8">
            <h2 className="text-2xl mb-2">
            Agree on what you should do <i>less</i> of
            </h2>
            <p className="text-gray-500">
            Write down the ideas and opportunities that you should do less of to progress
            </p>
          </div>
          <Alert
                  severity="warning"
                  icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  className=""
                >
                 When discussing opportunities: Set the stage for insights to occur - Let people generate insight on their own instead of telling them what to do by asking more how questions that help people reflect on the way forward (eg.about the process)
                </Alert>
        </div>
      </div>
    </div>
  );
};

export default ManagerReflection;
