import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faCircleXmark,
  faExclamationCircle,
  faLightbulbOn,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { IGoal, IGoalSteps } from "../interface/generic";
import Alert from "@mui/material/Alert";

export interface IProps {
  title?: string;
  isOpen?: boolean;
  popupFunction?: Function;
  updateGoal?: Function;
}

const AddGoal = (props: IProps) => {
  const axios = require("axios");
  const token = localStorage.getItem("token");
  const [goalSteps, setGoalSteps] = useState([]);
  const [goals, setGoals] = useState<IGoal>();
  const [stage, setStage] = useState("prepare");
  const [goalItems, setGoalItems] = useState([]);
  const [futureSteps, setFutureSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState<IGoalSteps>();
  const [agreementDate, setAgreementDate] = useState();
  const [agreementId, setAgreementId] = useState();
  const [currentFutureStep, setCurrentFutureStep] = useState("");
  const [isEditGoal, setIsEditGoal] = useState({
    status: false,
    item: "",
  });
  const [goalGlobalError, setGoalGlobalError] = useState({
    type: "",
    message: "",
  });

  const getUserProfile = () => {
    axios({
      method: "get",
      url: `https://api.qonvr.co/api/profile`,
      crossDomain: true,
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        if(data.agreements.length > 0) {
          setAgreementId(data.agreements[0].id);
          setAgreementDate(data.agreements[0].date);
        }

        // //console.log(data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const removeGoal = () => {
    if (isEditGoal.status) {
      setGoalItems(goalItems.filter((item) => item.name !== isEditGoal.item));
      setIsEditGoal({ ...isEditGoal, status: false });
      props.popupFunction(false);
    }
  };

  const removeStep = (step) => {
    setGoalSteps(goalSteps.filter((item) => item !== step));
  };

  const processStep = (type) => {
    if (type === "step") {
      if (Object.keys(currentStep).length > 0) {
        setGoalSteps((goalSteps) => [...goalSteps, currentStep]);
      }
    }
    
    if (type === "futureStep") {
      if (currentFutureStep) {
        setFutureSteps((futureSteps) => [...futureSteps, currentFutureStep]);
      }
    }
  };

  const checkGoalDate = (date) => {
    const then = new Date(date);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to days                 hour   min  sec   ms
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

    if (daysBetweenDates > 30) {
      // //console.log('beyond 30 days')
      return true;
    } else {
      // //console.log("less 30 days");
      setGoalGlobalError({
        ...goalGlobalError,
        type: "goalDate",
        message: "Your goal due date may not be less than 30 days",
      });
      return false;
    }
  };

  const checkGoalState = () => {
    if (goalSteps.length > 0) {
      // setGoalItems((goalItems) => [...goalItems, goals]);
      // setIsEditGoal({ ...isEditGoal, status: false });
      props.popupFunction(false);
      //console.log(goals)
      // final check done
      createGoal();
    } else {
      setGoalGlobalError({
        ...goalGlobalError,
        type: "goalSteps",
        message: "We need some opportunities to complete your goal",
      });
    }
  };

  const createGoal = () => {

    let urlString = `https://api.qonvr.co/api/goals/create/done`;

    axios({
      method: "post",
      url: urlString,
      crossDomain: true,
      data: [goals],
      headers: {
        'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        const data = response.data.profile;
        props.updateGoal()
        setGoals({})
        // //console.log(data);
      })
      .catch(function (error) {
        // handle error
        //console.log(error.response.data);
      });
  };

  useEffect(() => {
    getUserProfile();
    setStage("goal-1");
    return () => {};
  }, []);

  useEffect(() => {
    setGoals({ ...goals, steps: goalSteps });
  }, [goalSteps]);


  return (
    <Dialog open={props.isOpen} onClose={() => {}} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto container rounded-lg bg-white relative">
          <button
            onClick={() => props.popupFunction(false)}
            className="absolute -right-4 -top-4 rounded-full bg-red-500 w-8 h-8 text-white"
          >
            <FontAwesomeIcon icon={faXmark as IconProp} />
          </button>
          {stage === "goal-1" && (
            <div>
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    When giving your goal a title, word it in such a way that
                    would make it inspiring, and that you feel excited and
                    scared at the same time. So that ...
                  </Alert>
                </div>
                <div className="col-span-9 p-10 pl-0">
                  <h2 className="font-medium mb-4 text-2xl">
                    Lets start with your first goal. Give your goal a title
                  </h2>

                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                    placeholder="Example: Become a storytelling master"
                    value={goals?.name || ""}
                    onChange={(e) =>
                      setGoals({ ...goals, name: e.currentTarget.value })
                    }
                  />
                  {goalGlobalError.type === "goalName" && (
                    <Alert
                      onClose={() => {
                        setGoalGlobalError({
                          ...goalGlobalError,
                          type: "",
                          message: "",
                        });
                      }}
                      className="mb-5"
                      severity="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                        />
                      }
                    >
                      {goalGlobalError.message}
                    </Alert>
                  )}

                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-300"
                      onClick={(e) => {
                        removeGoal();
                      }}
                      disabled={!isEditGoal.status}
                    >
                      Remove Goal
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>

                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        goals.name
                          ? setStage("goal-2")
                          : setGoalGlobalError({
                              ...goalGlobalError,
                              type: "goalName",
                              message: "Your goal name is empty",
                            });
                      }}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {stage === "goal-2" && (
            <div>
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    Describe why it is important to you
                  </Alert>
                </div>
                <div className="col-span-9 p-10 pl-0">
                  <h2 className="font-medium mb-4 text-2xl">
                    Describe your goal
                  </h2>

                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                    placeholder="Example: Master the art of storytelling in the situation that matters. Able to communicate simply and clearly to senior management and team members in showcases and key meetings"
                    value={goals?.description || ""}
                    onChange={(e) =>
                      setGoals({
                        ...goals,
                        description: e.currentTarget.value,
                      })
                    }
                  />
                  {goalGlobalError.type === "goalDesc" && (
                    <Alert
                      onClose={() => {
                        setGoalGlobalError({
                          ...goalGlobalError,
                          type: "",
                          message: "",
                        });
                      }}
                      className="mb-5"
                      severity="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                        />
                      }
                    >
                      {goalGlobalError.message}
                    </Alert>
                  )}

                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => setStage("goal-1")}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        goals.description
                          ? setStage("goal-3")
                          : setGoalGlobalError({
                              ...goalGlobalError,
                              type: "goalDesc",
                              message: "Your goal description is empty",
                            });
                      }}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {stage === "goal-3" && (
            <div>
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    If the goal is a soft skill or not quantifiable, write it as
                    an outcome or feeling. What would people say or do when you
                    achieve this goal? How would you feel if you achieve it?
                  </Alert>
                </div>
                <div className="col-span-9 p-10 pl-0">
                  <h2 className="font-medium mb-4 text-2xl">
                    How are you measuring success?
                  </h2>

                  <textarea
                    id="about"
                    name="about"
                    rows={8}
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mb-8"
                    placeholder="Example: 
                I will be comfortable presenting in showcases and key steering committee meetings without feeling stunned or tongue-tied. I will get good feedback on how simply and clearly I can express the complex information in front of a big group"
                    value={goals?.measuring_success || ""}
                    onChange={(e) =>
                      setGoals({
                        ...goals,
                        measuring_success: e.currentTarget.value,
                      })
                    }
                  />
                  {goalGlobalError.type === "goalMeasure" && (
                    <Alert
                      onClose={() => {
                        setGoalGlobalError({
                          ...goalGlobalError,
                          type: "",
                          message: "",
                        });
                      }}
                      className="mb-5"
                      severity="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                        />
                      }
                    >
                      {goalGlobalError.message}
                    </Alert>
                  )}
                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => setStage("goal-2")}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        goals.measuring_success
                          ? setStage("goal-4")
                          : setGoalGlobalError({
                              ...goalGlobalError,
                              type: "goalMeasure",
                              message:
                                "How do you measure your success? its still empty.",
                            });
                      }}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {stage === "goal-4" && (
            <div className="p-5">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    Set a realistic time frame - we would recommend 30 days
                    minimum for achieving goal
                  </Alert>
                </div>
                <div className="col-span-9 p-10 pl-0">
                  <h2 className="font-medium mb-4 text-2xl">
                    When is the due date?
                  </h2>

                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md my-4"
                    aria-describedby="date"
                    value={goals?.completion_date || ""}
                    onChange={(e) => {
                      if (checkGoalDate(e.currentTarget.value)) {
                        setGoals({
                          ...goals,
                          completion_date: e.currentTarget.value,
                        });
                      }
                    }}
                  />

                  {goalGlobalError.type === "goalDate" && (
                    <Alert
                      onClose={() => {
                        setGoalGlobalError({
                          ...goalGlobalError,
                          type: "",
                          message: "",
                        });
                      }}
                      className="mb-5"
                      severity="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                        />
                      }
                    >
                      {goalGlobalError.message}
                    </Alert>
                  )}

                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => setStage("goal-3")}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        goals.completion_date
                          ? setStage("goal-5")
                          : setGoalGlobalError({
                              ...goalGlobalError,
                              type: "goalDate",
                              message: "Your goal due date may not be empty",
                            });
                        setGoals({
                          ...goals,
                          date: agreementDate,
                          agreement_id: agreementId,
                        });
                      }}
                    >
                      Next
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {stage === "goal-5" && (
            <div className="p-5">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-3 p-6 rounded-l-lg overflow-hidden  bg-indigo-100">
                  <img src="/set-goal-conv.png" alt="" className="mb-3" />
                  <Alert
                    className="mt-5"
                    severity="warning"
                    icon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
                  >
                    A goal can be overwhelming. Breaking it down into chunks
                    will help you see progress and achieving the goal more
                    likely.
                  </Alert>
                </div>
                <div className="col-span-9 p-10 pl-0">
                  <h2 className="font-medium mb-4 text-2xl">
                    What is the first step or action you have to take?
                  </h2>
                  <div className="bg-gray-100 rounded-md p-4 mt-5">
                    <div className="sm:flex sm:items-center">
                      <div className="w-9/12">
                        <input
                          type="text"
                          className="shadow focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Your steps / action towards your goal"
                          value={currentStep?.title || ""}
                          onChange={(e) =>
                            setCurrentStep({
                              ...currentStep,
                              title: e.currentTarget.value,
                              description: e.currentTarget.value,
                              status: false,
                            })
                          }
                        />
                      </div>
                      <button
                        type="button"
                        onClick={(e) => {
                          processStep("step");
                          setCurrentStep({});
                        }}
                        className="mt-3 w-3/12 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:text-sm"
                      >
                        Add step / action
                      </button>
                    </div>
                  </div>
                  {goals?.steps?.length >= 1 && (
                    <div className="border-2 border-gray-200 rounded-md p-4 mt-3 mb-8">
                      <ul className="divide-y divide-gray-300">
                        {goals?.steps?.map((step, i) => (
                          <li
                            key={i}
                            className="relative py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 text-sm "
                          >
                            <span className="flex items-center justify-between">
                              <p>{step.title}</p>
                              <button
                                onClick={() => {
                                  removeStep(step);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="text-base text-red-500"
                                  icon={faCircleXmark as IconProp}
                                />
                              </button>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {goalGlobalError.type === "goalSteps" && (
                    <Alert
                      onClose={() => {
                        setGoalGlobalError({
                          ...goalGlobalError,
                          type: "",
                          message: "",
                        });
                      }}
                      className="my-5"
                      severity="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                        />
                      }
                    >
                      {goalGlobalError.message}
                    </Alert>
                  )}

                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => setStage("goal-4")}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faArrowLeft as IconProp}
                      />
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={(e) => {
                        checkGoalState();
                      }}
                    >
                      Create this goal
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faArrowRight as IconProp}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {stage === "end-goal" && (
            <div className="p-5">
              <div className="text-center">
                <img src="/set-goal-conv.png" alt="" className="w-64 m-auto" />
                <h1 className="text-3xl mb-3 mt-8">Success!</h1>
                <p className="text-gray-500 mb-8">
                  Your first check-in will be in X weeks on dd/mm/yy
                </p>
                <a
                  href="/dashboard"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go to Dashboard
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowRight as IconProp}
                  />
                </a>
              </div>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default AddGoal;
