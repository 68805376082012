export interface IProps {
    title?: string
    description?: string
}

const Subheader = (props: IProps) => {


    return (
        <div className="text-center">
            <h2 className="text-3xl tracking-tight font-medium text-gray-900 sm:text-4xl">{props.title}</h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                {props.description}
            </p>
        </div>
    )
}

export default Subheader