import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { faHandsHelping } from "@fortawesome/pro-light-svg-icons";


export const GOALS_STATUS = {
    IN_PROGRESS: 'In Progress',
    ARCHIVED: 'Archived',
    PAUSED: 'Paused',
    RESET: 'Reset'
}

export const tiers = [
    {
      title: "Focused",
      id: "1",
      description:
        "A cadence that is great for increasing your development progress with more frequent conversations.",
      iconName: faBullseyePointer,
      themeClass: "bg-gradient-to-br from-pink-500 to-rose-500",
      inclusions: [
        'Goals Setting - Once',
        "Goals Reflection - Monthly",
        "Goals Check-In - Weekly",
      ],
      moreDetails: [
        "You can edit the goals anytime",
        "Suitable for  development goals with shorter time frames (1 month)",
        "Able to quickly increase engagement and development",
        "Recommended if focus is on a small group of high performers or team needs hand-holding",
      ],
      imageUrl: "/light.png",
    },
    {
      title: "Flexible",
      id: "2",
      description:
        "A flexible cadence that can accomodate a range of short, mid and long term goals.",
      iconName: faLink,
      themeClass: "bg-gradient-to-br from-cyan-400 to-light-blue-500",
      inclusions: [
        'Goals Setting - Once',
        "Goals Reflection - Once every 2 months",
        "Goals Check-In - Fortnightly",
      ],
      moreDetails: [
        "You can edit the goals anytime",
        "Flexible for a mix of mid to long term development goals",
        "Able to accommodate more members because of less frequent check-ins",
        "Recommended if you want to focus on a group of high performers",
      ],
      imageUrl: "/medium.png",
    },
    {
      title: "Supportive",
      id: "3",
      description:
        "A cadence that is suitable for mid to long term goals.  Suitable if you feel you don’t need a lot of handholding.",
      iconName: faHandsHelping,
      themeClass: "bg-gradient-to-br from-green-400 to-cyan-500",
      inclusions: [
        'Goals Setting - Once',
        "Goals Reflection - Once every 3 months",
        "Goals Check-In - Monthly",
      ],
      moreDetails: [
        "You can edit the goals anytime",
        "Suitable for a mix of mid to long term development goals",
        "Recommended for self-driven members without need for more frequent check-ins",
      ],
      imageUrl: "/high.png",
    },
  ];
  