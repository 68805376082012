import { useContext, useEffect, useState } from 'react'
import Subheader from '../components/subheader';
import {
    CheckIcon, InformationCircleIcon,
} from '@heroicons/react/outline'
import Agreement from '../components/agreement';
import { ProfileContext } from '../context/profileProvider';

import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-light-svg-icons'
import { faHandsHelping } from '@fortawesome/pro-light-svg-icons'
import { IConversationFrequency, IOnboardingDeals, IOnboardingProps, IUserProfile } from '../interface/generic';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IHeader {
    Title?: string;
    Description?: string;
}

const tiers = [
    {
        id: 1,
        title: 'Focused',
        description: 'A cadence that is great for increasing your development progress with more frequent conversations.',
        iconName: faBullseyePointer,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Monthly',
            'Goals Check-In - Weekly'
        ],
        moreDetails: [
            'Weekly check-ins',
            'Suitable for  development goals with shorter time frames (1 month)',
            'Able to quickly increase engagement and development',
            'Recommended if focus is on a small group of high performers or team needs hand-holding'
        ],
        imageUrl: '/light.png'
    },
    {
        id: 2,
        title: 'Flexible',
        description: 'A flexible cadence that can accomodate a range of short, mid and long term goals.',
        iconName: faLink,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Once every 2 months',
            'Goals Check-In - Fortnightly'
        ],
        moreDetails: [
            'Fortnightly check-ins',
            'Flexible for a mix of mid to long term development goals',
            'Able to accommodate more members because of less frequent check-ins',
            'Recommended if you want to focus on a group of high performers'
        ],
        imageUrl: '/medium.png'
    },
    {
        id: 3,
        title: 'Supportive',
        description: 'A cadence that is suitable for mid to long term goals.  Suitable if you feel you don’t need a lot of handholding.',
        iconName: faHandsHelping,
        inclusions: [
            'Goals Setting - Once',
            'Goals Reflection - Once every 3 months',
            'Goals Check-In - Monthly'
        ],
        moreDetails: [
            'Monthly check-ins',
            'Suitable for a mix of mid to long term development goals',
            'Recommended for more self-driven members without frequent check-ins'
        ],
        imageUrl: '/high.png'
    }
]
const welcomeTextsEndUser = [
    {
        title: `Agree when & how often`,
        href: '#',
        description:
            '',
        imageUrl:
            '/make-deal.png',
    },
    {
        title: 'Kick off goal setting',
        href: '#',
        description:
            '',
        imageUrl:
            '/set-goal.png',
    }, {
        title: 'Progress on your development - Start check-ins and reflections, track your goals.',
        href: '#',
        description:
            '',
        imageUrl:
            '/track-development.png',
    },
]
const welcomeTextsUserSelectPlan = [
    {
        title: `Select 'when' & 'how often'`,
        href: '#',
        description:
            'Discuss and decide together on a realistic development conversation cadence.',
        imageUrl:
            '/make-deal.png',
    },
    {
        title: 'Kick off goal setting',
        href: '#',
        description:
            'Next, prepare and have the goal setting conversation to set goals collaboratively',
        imageUrl:
            '/set-goal.png',
    }, {
        title: 'Track goals progress',
        href: '#',
        description:
            'Set actions and have structured conversations to progress on your goals. ',
        imageUrl:
            '/track-development.png',
    },
]
const welcomeTextsManagerSelectPlan = [
    {
        title: `Select 'when' & 'how often'`,
        href: '#',
        description:
            'Review and agree to the cadence selected by your manager/lead/coach.',
        imageUrl:
            '/make-deal.png',
    },
    {
        title: 'Kick off goal setting',
        href: '#',
        description:
            'Next, prepare and have the goal setting conversation to set goals collaboratively',
        imageUrl:
            '/set-goal.png',
    }, {
        title: 'Track goals progress',
        href: '#',
        description:
            'Set actions and have structured conversations to progress on your goals.',
        imageUrl:
            '/track-development.png',
    },
]

export const Onboarding = (props: IOnboardingProps) => {
      // user profile context
    const { userProfile } = useContext(ProfileContext);
    const [userData, setUserData] = useState<IUserProfile>()
    const axios = require("axios");
    const token = localStorage.getItem("token");
    let userSelectPlan = false
    let welcomeTextPlan = []

    //console.log(userProfile)

    if (userProfile.organisation.plan_selected === 'endUser') {
        userSelectPlan = true
    }

    if(userProfile.user_type === 'endUser') {
        if (userSelectPlan) {
            welcomeTextPlan = welcomeTextsEndUser
        } else {
            welcomeTextPlan = welcomeTextsEndUser
        }
    } else {
        if (userSelectPlan) {
            welcomeTextPlan = welcomeTextsUserSelectPlan
        } else {
            welcomeTextPlan = welcomeTextsManagerSelectPlan
        }
    }

    const getUserProfile = () => {
        axios({
          method: "get",
          url: `https://api.qonvr.co/api/profile`,
          crossDomain: true,
          headers: {
            'Authorization': `Bearer ${token}`,
'Access-Control-Allow-Origin': '*',
          },
        })
          .then(function (response) {
            console.log(response)
            setUserData(response.data.profile)
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          })
          .finally(() => {

          });
      };
   

    const selectedParam = tiers.filter(tier => tier.id === userProfile.organisation.agreement_plan_id)

    const [header, setHeader] = useState<IHeader>({
        Title: `Welcome to Qonvr`,
        Description: `Invest in quality conversations to progress on development goals`
    });

    const [conversationFrequency, setConversationFrequency] = useState<IConversationFrequency>({
        id: null,
        title: '',
        description: '',
        inclusions: [],
        imageUrl: '',
        iconName: null,
        moreDetails: []
    })

    const [currentPage, setCurrentPage] = useState('splash');

    const nextPage = (section, title, description, frequency?: IConversationFrequency) => {
        setHeader({ ...header, Title: title, Description: description });
        frequency && setConversationFrequency({ ...conversationFrequency, id:frequency.id, title: frequency.title, description: frequency.description, inclusions: frequency.inclusions, imageUrl: frequency.imageUrl, iconName: frequency.iconName, moreDetails: frequency.moreDetails })
        setCurrentPage(section)
    }
    useEffect(() => {
    }, [currentPage])

    useEffect(() => {
        getUserProfile()
    }, [])

    return (
        <>
      
            {/* <Header /> */}
            <div className="relative pt-16 pb-20  lg:pt-10 lg:pb-10">
                <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto">
                {userData && !userData?.manager[0].status_onboarding && <div className="rounded-md bg-yellow-50 p-4 mb-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-yellow-700">Oops! Your manager or lead has not completed their onboarding, please remind them to complete it if before you can continue.</p>
                                    </div>
                                </div>
                                </div>}
                    <Subheader title={header.Title} description={header.Description} />
                    {currentPage === 'splash' &&
                        <>
                            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

                                {welcomeTextPlan.map((post) => (
                                    <div key={post.title} className="flex flex-col rounded-lg overflow-hidden  shadow">
                                        <div className="flex-shrink-0">
                                            <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                                        </div>
                                        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                            <div className="flex-1">

                                                <div className="block mt-2">
                                                    <p className="text-xl font-medium text-gray-900">{post.title}</p>
                                                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12 text-center">
                                {
                                    userSelectPlan  ?
                                        <button disabled={!userData?.manager[0].status_onboarding} className="disabled:bg-gray-300 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" onClick={(e) => nextPage('deals', 'Select a development plan', 'How often do you want your development conversations to be?')}>Get started</button>
                                    :
                                        <button disabled={!userData?.manager[0].status_onboarding}
                                        onClick={ (e) => { nextPage('agreement', `Let's agree`, 'Commit to having focused growth conversations', { title: selectedParam[0].title, description: selectedParam[0].description, imageUrl: selectedParam[0].imageUrl, inclusions: selectedParam[0].inclusions, iconName: selectedParam[0].iconName as IconProp })}}
                                        className='disabled:bg-gray-300 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>Get started</button> 
                                    }
                            </div>
                        </>
                    }

                    {currentPage === 'deals' &&
                        <>
                            <div className="grid grid-cols-12 gap-6 mt-12">
                                <div className="col-span-12 lg:col-span-12 ">
                                    <div className="h-full space-y-4  sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                                        {tiers.map((tier) => (
                                            <div key={tier.title} className="relative">
                                                <div key={tier.title} className="relative z-10 rounded-t-lg bg-white overflow-hidden ">
                                                    <div className="p-6 pb-4 pt-10">
                                                        <FontAwesomeIcon icon={tier.iconName as IconProp} className='text-6xl text-gray-300' />
                                                        <h2 className="mt-5 text-2xl leading-6 font-medium text-gray-900">{tier.title}</h2>
                                                        <p className="mt-5 text-sm text-gray-700 h-16">{tier.description}</p>
                                                    </div>
                                                    <div className="pt-2 px-6">
                                                        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                                                        <ul className="mt-6 space-y-4">
                                                            {tier?.inclusions?.map((feature) => (
                                                                <li key={feature} className="flex space-x-3">
                                                                    <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                                                    <span className="text-sm text-gray-500">{feature}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="pt-6 pb-6 px-6">
                                                        <p className='text-sm text-gray-500'>Discuss with your manager, lead or coach before selecting</p>
                                                        <button
                                                            onClick={(e) => {
                                                                // setOnboardingDeals({...onboardingDeals, agreement_plan_id: tier.id, to_user: userProfile.manager[0].id})
                                                                nextPage('agreement', `Let's agree`, 'Commit to having focused growth conversations', {id: tier.id, title: tier.title, description: tier.description, imageUrl: tier.imageUrl, inclusions: tier.inclusions, iconName: tier.iconName as IconProp })}}
                                                            className='mt-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>Select</button>
                                                    </div>
                                                </div>
                                                <div className="h-72 z-0 rounded-b-lg bg-gray-100 overflow-hidden  shadow">
                                                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase p-6 pb-0">More details</h3>
                                                    <ul className="mt-6 space-y-4 pt-0 p-6 pb-8">
                                                        {tier?.moreDetails?.map((details) => (
                                                            <li key={details} className="flex space-x-3">
                                                                <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                                                <span className="text-sm text-gray-500">{details}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                    {currentPage === 'agreement' &&
                        <Agreement conversationFrequency={conversationFrequency} />
                    }
                </div>
            </div>

        </>
    );
}

export default Onboarding;