
export interface ILoading {
    text?: string
    layout?: string
    className?: string
}

const Loading = (props: ILoading) => {

    return (
      <>
        {props.layout === "page" ? (
          <div className={`fixed w-full h-full bg-white left-0 top-0 transition-all ${props.className && props.className}`}>
            <div className="flex flex-col items-center justify-center w-full h-full">
              <p>{props.text ? props.text : "Loading..."}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle cx="30" cy="50" fill="#e90c59" r="20">
                  <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="30;70;30"
                    begin="-0.5s"
                  ></animate>
                </circle>
                <circle cx="70" cy="50" fill="#46dff0" r="20">
                  <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="30;70;30"
                    begin="0s"
                  ></animate>
                </circle>
                <circle cx="30" cy="50" fill="#e90c59" r="20">
                  <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;0.5;1"
                    values="30;70;30"
                    begin="-0.5s"
                  ></animate>
                  <animate
                    attributeName="fill-opacity"
                    values="0;0;1;1"
                    calcMode="discrete"
                    keyTimes="0;0.499;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                  ></animate>
                </circle>
              </svg>
            </div>
          </div>
        ) : (
          <div className={`flex flex-col items-center justify-center w-full transition-all  ${props.className && props.className}`}>
            <p>{props.text ? props.text : "Loading..."}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle cx="30" cy="50" fill="#e90c59" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
              </circle>
              <circle cx="70" cy="50" fill="#46dff0" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="0s"
                ></animate>
              </circle>
              <circle cx="30" cy="50" fill="#e90c59" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
                <animate
                  attributeName="fill-opacity"
                  values="0;0;1;1"
                  calcMode="discrete"
                  keyTimes="0;0.499;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              </circle>
            </svg>
          </div>
        )}
      </>
    );
}

export default Loading