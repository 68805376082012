import { useState, createContext, useEffect } from "react";
import { IUser, IUserProfile } from "../interface/generic";

export const ProfileContext = createContext(null);

const ProfileProvider = ({ children }) => {
    
    const [userProfile, setUserProfile] = useState<IUser>()

    const getUserProfile = () => {
        
      const tokenString = localStorage.getItem('profile');
      if (tokenString) {
        const userDetails = JSON.parse(tokenString);
        setUserProfile(userDetails.profile)
      }
  
    }
  
    useEffect(() => {
      getUserProfile()
    }, [])
    
    const contextValue = {
        userProfile,
        setUserProfile
    };

    return (
        <>{userProfile && 
        <ProfileContext.Provider value={contextValue}>
            {children}
        </ProfileContext.Provider>
}
        </>
    );
};

export default ProfileProvider;